import http from '../helpers/http';

const SchoolScheduleEvents = (token: string) => {
  const basePath = '/school-schedule-event';

  return {
    getEventsBySchoolIdAndMonth: async (filters: { schoolId: any; type?: string; startAt: string }) => {
      const params = filters;
      const result = await http.get(`${basePath}/${filters.schoolId}`, {
        headers: {
          'x-access-token': token,
        },
        params,
      });
      return result.data;
    },
    importFromFile: async (schoolId: string, type: string, body: FormData) => {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': token,
        },
      };
      const result = await http.patch(`${basePath}/${schoolId}/${type}/import-from-file`, body, headers);
      return result.data;
    },
    show: async (scheduleId: string) => {
      const result = await http.get(`${basePath}/show/${scheduleId}`, {
        headers: {
          'x-access-token': token,
        },
      });
      return result.data;
    },
    create: async (body: {}) => {
      const headers = {
        headers: {
          'x-access-token': token,
        },
      };
      const result = await http.post(`${basePath}`, body, headers);
      return result.data;
    },
    update: async (scheduleId: string, body: {}) => {
      const headers = {
        headers: {
          'x-access-token': token,
        },
      };
      const result = await http.put(`${basePath}/${scheduleId}`, body, headers);
      return result.data;
    },
    delete: async (id: string) => {
      const result = await http.delete(`${basePath}/${id}`, {
        headers: {
          'x-access-token': token,
        },
      });
      return result.data;
    },
  };
};

export default SchoolScheduleEvents;
