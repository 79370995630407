import { useContext, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';

const Logout = () => {
  const { handleLogout } = useContext(AuthContext);
  useEffect(() => {
    handleLogout();
  }, []);

  return <></>;
};

export default Logout;
