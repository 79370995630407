import { useEffect, useState, useContext } from 'react';
import Employee from '../../../services/Employee';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import DataTable from '../../../components/Core/DataTableWithoutHeader';
import { Button, Col, FormGroup, Input, Label, Modal, Row } from 'reactstrap';
import { createOptionsPage } from '../../../entities/OptionsPaged';
import { createPagedMeta } from '../../../entities/PagedMeta';
import PayRatesListColumns from './PayRatesListColumns';
import { toast } from 'react-toastify';

const PayRatesList = ({ employeeId }: { employeeId: string }) => {
  const { token }: any = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState<any>([]);
  const dataSelected = tableData.data?.find((d: any) => d.selected);
  const [filteredOptions, setFilteredOptions] = useState({
    filters: [],
    options: createOptionsPage(createPagedMeta(), { sortDesc: 'desc' }),
  });
  const [lastUpdatedAt, setLastUpdatedAt] = useState(new Date().getTime());

  const setSelected = (id: string | null) => {
    const data = tableData.data?.map((d: any) => {
      return { ...d, ...{ selected: false } };
    });
    const selected = tableData.data?.find((d: any) => d.id === id);
    if (!selected) {
      setTableData(JSON.parse(JSON.stringify({ meta: tableData.meta, data })));
      return false;
    }
    const index = tableData.data.indexOf(selected);
    data[index].selected = !selected.selected;
    setTableData(JSON.parse(JSON.stringify({ meta: tableData.meta, data })));
  };

  const handlePerRowsChange = async (perPage: number) => {
    const newOptions = {
      ...createPagedMeta(),
      perPage,
    };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options: createOptionsPage(newOptions, { sortDesc: 'desc' }),
    };
    setFilteredOptions(newFilteredOptions);
  };

  const handlePageChange = async (page: number) => {
    const newOptions = {
      ...createPagedMeta(),
      currentPage: page,
    };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options: createOptionsPage(newOptions, { sortDesc: 'desc' }),
    };
    setFilteredOptions(newFilteredOptions);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data = new FormData(e.target as HTMLFormElement);
      const body = {} as any;
      for (const [key, value] of data.entries()) {
        (body as any)[key] = value;
      }
      await Employee(token).updatePayRateDescription(employeeId, dataSelected?.id, body);
      toast.success('Update success');
      setLastUpdatedAt(new Date().getTime());
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid Request');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!employeeId) return;
    const load = async () => {
      setLoading(true);
      try {
        const response = await Employee(token).listPayRates(employeeId, filteredOptions);
        setTableData({
          meta: response.meta,
          data: response.data.map((r: any) => {
            return { ...{ selected: false }, ...r };
          }),
        });
      } catch (e) {
        console.error('Failed to load pay rates');
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [employeeId, lastUpdatedAt, filteredOptions]);
  return (
    <>
      <Modal className="modal-dialog-centered" isOpen={!!dataSelected} toggle={() => setSelected(null)}>
        <form onSubmit={handleSubmit}>
          <div className="modal-header pb-0">
            <h5 className="modal-title" id="Label">
              {`Description`}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setSelected(null)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body pt-0">
            <Row>
              <Col
                xs="12"
                style={{ maxHeight: '350px', overflowY: 'scroll' }}
                dangerouslySetInnerHTML={{
                  __html:
                    '<p class="p-0 m-0">' +
                    dataSelected?.description.map((d: any) => d.text).join('</p><p class="p-0 m-0">') +
                    '</p>',
                }}
              />
            </Row>
            <hr />
            <Row>
              <Col xs="12">
                <FormGroup className="form-control-label">
                  <Label>Add Description</Label>
                  <Input type="textarea" name="description" required />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="text-right">
                <Button className="btn-danger" type="button" onClick={() => setSelected(null)}>
                  Close
                </Button>
                <Button className="btn-success" type="submit">
                  Save
                </Button>
              </Col>
            </Row>
          </div>
        </form>
      </Modal>
      <form>
        <h6 className="heading-small text-muted mb-2 mt-4">Employees Pay Rates</h6>
        <DataTable
          columns={PayRatesListColumns({ maxDescriptionLength: 25, setSelected })}
          data={tableData.data}
          totalRows={tableData.meta?.total}
          loading={loading}
          title=""
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          conditionalRowStyles={[
            {
              when: (row: any) => !row.deletedAt,
              style: {
                backgroundColor: 'rgb(212, 255, 225)',
              },
            },
          ]}
        />
      </form>
    </>
  );
};

export default PayRatesList;
