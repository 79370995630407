/* eslint-disable max-lines */
import { Button, FormGroup, Form, Input, Row, Col, Label } from 'reactstrap';
import { toast } from 'react-toastify';
import { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import InputMask from 'react-input-mask';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import { Context as AuthContext } from '../../../../contexts/AuthContext';
import UserService from '../../../../services/User';
import EmployeeService from '../../../../services/Employee';
import SchoolService from '../../../../services/School';
import EmployeeTitlesService from '../../../../services/EmployeeTitles';
import EmployeeDepartmentsService from '../../../../services/EmployeeDepartments';
import { toSystemFormat, toLocaleFormatSlash } from '../../../../helpers/dateFormat';
import EmployeeSettingsModal from '../EmployeeSettingsModal';
import isAdmin from '../../../../helpers/isAdmin';
import Messaging from '../../../../services/Messaging';
import Pto from '../../../../services/Pto';
import isUpperManagment from '../../../../helpers/isUpperManagment';
import isDirector from '../../../../helpers/isDirector';
import InputWithHideValue from '../../../../components/Core/InputWithHideValue';
import confirmAlert from '../../../../helpers/confirmAlert';
import ModalProfile from '../../../../components/Profile/ModalProfile';
import { PayRatesForm } from '../../../../components/Employee';

const EmployeeDetailsTab = () => {
  const { id: userId } = useParams<{ id: string }>();
  const { token, currentFranchisee, user, currentSchool }: any = useContext(AuthContext);
  const isUserAdmin = isAdmin(user);
  const hasSettingsPermission = isUserAdmin;

  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [settingsModal, setSettingsModal] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [schoolList, setSchoolList] = useState([{}]);
  const [schoolId, setSchoolId] = useState('');
  const [franchiseeId, setFranchiseeId] = useState('');
  const [titleId, setTitleId] = useState('');
  const [titleList, setTitleList] = useState([{}]);
  const [departmentId, setDepartmentId] = useState('');
  const [departmentList, setDepartmentList] = useState([{}]);
  const [supervisorId, setSupervisorId] = useState('');
  const [supervisorCompareId, setSupervisorCompareId] = useState('');
  const [employees, setEmployees] = useState<any>([]);
  const [startDate, setStartDate] = useState('');
  const [terminationDate, setTerminationDate] = useState('');
  const [shirtSize, setShirtSize] = useState('');
  const [hoursPerWeek, setHoursPerWeek] = useState(0);
  const [showOnTimecard, setShowOnTimecard] = useState(false);
  const [billingEmails, setBillingEmails] = useState(false);
  const [canTour, setCanTour] = useState(false);
  const [cameraAccess, setCameraAccess] = useState(false);
  const [canReciveSupportTickets, setCanReciveSupportTickets] = useState(false);
  const [canAccessMultipleCenters, setCanAccessMultipleCenters] = useState(false);
  const [multipleCenters, setMultipleCenters] = useState<any>([]);
  const [workday, setWorkday] = useState('PT');
  const [paymentFrequency, setPaymentFrequency] = useState<string>();
  const [payRate, setPayRate] = useState<string>();
  const [policy, setPolicy] = useState<string>();
  const [ptoPolices, setPtoPolices] = useState([] as any[]);
  const [ssn, setSsn] = useState('');
  const [modalUpdatePayment, setModalUpdatePayment] = useState(false);
  const canEditUser =
    isAdmin(user) ||
    (isUpperManagment(user) && userId !== user.id) ||
    (isDirector(user) && supervisorCompareId === user.id);

  useEffect(() => {
    setFranchiseeId(currentFranchisee.value);
    getById();
    listSelectSchool();
    listTitles();
    listDepartments();
    listEmployees(currentSchool.value);
    listPtoPolices();
  }, []);

  useEffect(() => {
    if (schoolId) listEmployees(schoolId);
  }, [schoolId]);

  useEffect(() => {
    setWorkday(hoursPerWeek < 32 ? 'PT' : 'FT');
  }, [hoursPerWeek]);

  const listPtoPolices = async () => {
    try {
      const result = await Pto(token).ptoPolicyList();
      setPtoPolices(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No data Found!');
    }
  };

  const listSelectSchool = async () => {
    try {
      const params = { filters: [{ field: 'franchiseeId', opration: 'hash', value: currentFranchisee.value }] };
      const result = await SchoolService(token).listSelect(params);
      setSchoolList(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No schools found');
    }
  };

  const listEmployees = async (idSchool: string) => {
    if (!idSchool) {
      return;
    }
    try {
      const { data } = await Messaging(token).listSelectStaff(idSchool);
      const response = data.filter((value: any) => value.userId !== userId);

      setEmployees(response);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No schools found');
    }
  };

  const listTitles = async () => {
    try {
      const result = await EmployeeTitlesService(token).list();
      setTitleList(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No titles found');
    }
  };

  const listDepartments = async () => {
    try {
      const result = await EmployeeDepartmentsService(token).list();
      setDepartmentList(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No departments found');
    }
  };

  const getById = async () => {
    setIsDisabled(true);
    try {
      const result = await UserService(token).show(userId);
      setUserRole(result?.data?.groupId || '');
      if (result?.data?.employee) {
        const employee = result?.data?.employee;
        setEmployeeId(employee?.id || '');
        setSchoolId(employee?.schoolId || '');
        setTitleId(employee?.titleId || '');
        setDepartmentId(employee?.departmentId || '');
        setSupervisorId(employee?.supervisorId || '');
        setSupervisorCompareId(employee?.supervisorId || '');
        setPolicy(employee?.ptoPolicyId || '');
        setStartDate(employee?.startDate ? toLocaleFormatSlash(employee?.startDate) : '');
        setTerminationDate(employee?.terminationDate ? toLocaleFormatSlash(employee?.terminationDate) : '');
        setShirtSize(employee?.shirtSize || '');
        setHoursPerWeek(Number(employee?.hoursPerWeek || 0));
        setWorkday(employee?.workday || '');
        setPaymentFrequency(employee?.currentPayRate?.paymentFrequency || '');
        setPayRate(
          employee?.currentPayRate?.payRate &&
            parseFloat(employee?.currentPayRate?.payRate).toLocaleString('en-IN', {
              style: 'currency',
              currency: 'USD',
            }),
        );
        setShowOnTimecard(employee?.showOnTimecard || false);
        setBillingEmails(employee?.billingEmails || false);
        setCanTour(employee?.canTour || false);
        setCameraAccess(employee?.cameraAccess || false);
        setCanReciveSupportTickets(employee?.canReciveSupportTickets || false);
        setCanAccessMultipleCenters(employee?.canAccessMultipleCenters || false);
        if (employee?.employeeSchools) {
          const schools = employee?.employeeSchools
            .map((e: any) => (e?.school?.id ? { value: e?.school?.id, label: e?.school?.name } : null))
            .filter((e: any) => !!e);
          setMultipleCenters(schools);
        }
        setSsn(employee?.ssn || '');
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid user ID');
    } finally {
      setIsDisabled(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    setIsDisabled(true);

    const body = {
      userId,
      groupId: userRole,
      schoolId,
      franchiseeId,
      titleId,
      departmentId,
      supervisorId,
      ptoPolicyId: policy,
      startDate: startDate ? toSystemFormat(startDate) : '',
      terminationDate: terminationDate ? toSystemFormat(terminationDate) : '',
      shirtSize,
      hoursPerWeek,
      workday,
      paymentFrequency,
      payRate: Number(payRate).toFixed(2),
      showOnTimecard,
      billingEmails,
      canTour,
      cameraAccess,
      canReciveSupportTickets,
      canAccessMultipleCenters,
      multipleCenters: multipleCenters ? multipleCenters.map((c: any) => c.value) : [],
      ssn: ssn.replaceAll('-', ''),
    };

    try {
      employeeId ? await EmployeeService(token).update(employeeId, body) : await EmployeeService(token).create(body);
      toast.success('Updated successfully!');
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Update error!');
      toast.error(message);
    } finally {
      setIsDisabled(false);
    }

    setLoading(false);
    getById();
  };

  const handleUpdatePayment = async (e: React.FormEvent) => {
    if (userId) {
      e.preventDefault();
      const id = user?.id || '';
      return confirmAlert({
        title: 'Confirm',
        messageType: 'EDIT',
        message: 'Are you sure you want to update payment?',
        id,
        onClickYes: () => {
          // setSelectedUser({ ...{ employeeId: user.id }, ...user.user });
          setModalUpdatePayment(!modalUpdatePayment);
        },
      });
    }
  };

  return (
    <>
      <ModalProfile
        className="modal-dialog-centered"
        isOpen={modalUpdatePayment}
        toggle={() => {
          setModalUpdatePayment(!modalUpdatePayment);
        }}
        size={'xs'}
        user={{ id: userId }}
      >
        <PayRatesForm
          id={employeeId}
          onSubmit={(_) => {
            setModalUpdatePayment(!modalUpdatePayment);
            getById();
          }}
        />
      </ModalProfile>
      <Row>
        {hasSettingsPermission && (
          <i className="fas fa-cog fa-lg float-right cursor-pointer" onClick={() => setSettingsModal(true)}></i>
        )}
        <Form onSubmit={handleSubmit}>
          <div className="pl-lg-4 mt-3">
            <Row>
              <Col lg="6">
                <FormGroup>
                  <Label className="form-control-label">School</Label>
                  <Input
                    type="select"
                    onChange={(e) => setSchoolId(e.target.value)}
                    value={schoolId}
                    required
                    disabled={!canEditUser}
                  >
                    <option value=""></option>
                    {schoolList?.map((filter: any, index: number) => {
                      return (
                        <option value={filter.id} key={`school-${index}`}>
                          {filter.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <Label className="form-control-label">Title</Label>
                  <Input
                    type="select"
                    onChange={(e) => setTitleId(e.target.value)}
                    value={titleId}
                    required
                    disabled={!canEditUser}
                  >
                    <option value=""></option>
                    {titleList?.map((filter: any, index: number) => {
                      return (
                        <option value={filter.id} key={`title-${index}`}>
                          {filter.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <Label className="form-control-label">Department</Label>
                  <Input
                    type="select"
                    onChange={(e) => setDepartmentId(e.target.value)}
                    value={departmentId}
                    required
                    disabled={!canEditUser}
                  >
                    <option value=""></option>
                    {departmentList?.map((filter: any, index: number) => {
                      return (
                        <option value={filter.id} key={`department-${index}`}>
                          {filter.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <Label className="form-control-label">Supervisor</Label>
                  <Input
                    type="select"
                    onChange={(e) => setSupervisorId(e.target.value)}
                    value={supervisorId}
                    required={true}
                    disabled={!canEditUser}
                  >
                    <option value=""></option>
                    {employees?.map((filter: any, index: number) => {
                      return (
                        <option value={filter.userId} key={`user-role-${index}`}>
                          {`${filter.firstName} ${filter.lastName}`}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label">SSN</label>
                  <InputWithHideValue
                    mask="999-99-9999"
                    method={async () => await EmployeeService(token).showSsn(employeeId)}
                    onChange={(e) => setSsn(e)}
                    disabled={isDisabled || !canEditUser}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <label className="form-control-label">Start Date</label>
                  <Input
                    className="form-control-alternative"
                    placeholder="Start Date"
                    type="text"
                    mask="99/99/9999"
                    tag={InputMask}
                    disabled={isDisabled || !canEditUser}
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <label className="form-control-label">Termination Date</label>
                  <Input
                    className="form-control-alternative"
                    placeholder="Termination Date"
                    type="text"
                    mask="99/99/9999"
                    tag={InputMask}
                    disabled={isDisabled || !canEditUser}
                    value={terminationDate}
                    onChange={(e) => setTerminationDate(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Row>
                    <Col>
                      <label className="form-control-label">Payment Frequency</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Input
                        type="select"
                        value={paymentFrequency}
                        onChange={(e) => setPaymentFrequency(e.target.value)}
                        required
                        disabled={!canEditUser}
                      >
                        <option value=""></option>
                        <option value={'hourly'}>Hourly</option>
                        <option value={'annually'}>Annually</option>
                      </Input>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <label className="form-control-label">Hours Per Week</label>
                  <Input
                    className="form-control-alternative"
                    placeholder="Hours Per Week"
                    type="number"
                    disabled={isDisabled || !canEditUser}
                    value={hoursPerWeek}
                    onChange={(e) => setHoursPerWeek(Number(e.target.value))}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="3">
                <FormGroup>
                  <Row>
                    <Col>
                      <label className="form-control-label">Type</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Input
                        type="select"
                        value={workday}
                        onChange={(e) => setWorkday(e.target.value)}
                        required
                        disabled={!canEditUser}
                      >
                        <option value={'PT'}>PT</option>
                        <option value={'FT'}>FT</option>
                        <option value={'SUB'}>SUB</option>
                      </Input>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              {paymentFrequency && (
                <Col lg="3">
                  <FormGroup>
                    <label className="form-control-label">Pay Rate</label>
                    <div className="input-group mb-3">
                      <Input
                        type="text"
                        thousandsGroupStyle="thousand"
                        disabled={isDisabled || !canEditUser}
                        prefix="$"
                        decimalSeparator="."
                        displayType="input"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        tag={NumberFormat}
                        value={payRate}
                        onValueChange={(e: any) => setPayRate(e.floatValue)}
                        readOnly={true}
                      />
                      <div className="input-group-append">
                        <button className="btn btn-danger" type="button" onClick={(e) => handleUpdatePayment(e)}>
                          Change
                        </button>
                      </div>
                    </div>
                  </FormGroup>
                </Col>
              )}

              <Col lg="3">
                <FormGroup>
                  <label className="form-control-label">Shirt Size</label>
                  <Input
                    className="form-control-alternative"
                    placeholder="Shirt Size"
                    type="text"
                    disabled={isDisabled || !canEditUser}
                    value={shirtSize}
                    onChange={(e) => setShirtSize(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col lg="3">
                <FormGroup>
                  <Row>
                    <Col>
                      <label className="form-control-label">Force To Show On Timecard</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label className="custom-toggle">
                        <Input
                          type="checkbox"
                          onChange={(e) => setShowOnTimecard(e.target.checked)}
                          checked={showOnTimecard}
                          disabled={!canEditUser}
                        >
                          <option className="custom-toggle-slider rounded-circle" />
                        </Input>
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <Row>
                    <Col>
                      <label className="form-control-label">PTO Policy</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Input
                        type="select"
                        value={policy}
                        onChange={(e) => setPolicy(e.target.value)}
                        required
                        disabled={!canEditUser}
                      >
                        <option value={''}></option>
                        {ptoPolices.map((value) => (
                          <option key={value.id} value={value.id}>
                            {value.name}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col lg="3">
                <FormGroup>
                  <Row>
                    <Col>
                      <label className="form-control-label">Receive Billing Emails</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label className="custom-toggle">
                        <Input
                          type="checkbox"
                          onChange={(e) => setBillingEmails(e.target.checked)}
                          checked={billingEmails}
                          disabled={!canEditUser}
                        >
                          <option className="custom-toggle-slider rounded-circle" />
                        </Input>
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col lg="3">
                <FormGroup>
                  <Row>
                    <Col>
                      <label className="form-control-label">Can Tour</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label className="custom-toggle">
                        <Input
                          type="checkbox"
                          onChange={(e) => setCanTour(e.target.checked)}
                          checked={canTour}
                          disabled={!canEditUser}
                        >
                          <option className="custom-toggle-slider rounded-circle" />
                        </Input>
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <Row>
                    <Col>
                      <label className="form-control-label">Camera Access</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label className="custom-toggle">
                        <Input
                          type="checkbox"
                          onChange={(e) => setCameraAccess(e.target.checked)}
                          checked={cameraAccess}
                          disabled={!canEditUser}
                        >
                          <option className="custom-toggle-slider rounded-circle" />
                        </Input>
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <Row>
                    <Col>
                      <label className="form-control-label">Can receive Support Tickets</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label className="custom-toggle">
                        <Input
                          type="checkbox"
                          onChange={(e) => setCanReciveSupportTickets(e.target.checked)}
                          checked={canReciveSupportTickets}
                          disabled={!canEditUser}
                        >
                          <option className="custom-toggle-slider rounded-circle" />
                        </Input>
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <Row>
                    <Col>
                      <label className="form-control-label">Can Access Multiple Centers</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label className="custom-toggle">
                        <Input
                          type="checkbox"
                          onChange={(e) => {
                            setCanAccessMultipleCenters(e.target.checked);
                            if (!e.target.checked) setMultipleCenters([]);
                          }}
                          checked={canAccessMultipleCenters}
                          disabled={!canEditUser}
                        >
                          <option className="custom-toggle-slider rounded-circle" />
                        </Input>
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              {canAccessMultipleCenters ? (
                <Col lg="6">
                  <FormGroup>
                    <Row>
                      <Col>
                        <label className="form-control-label">Centers</label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Select
                          className="form-control-alternative"
                          onChange={(e) => setMultipleCenters(e)}
                          value={multipleCenters}
                          isMulti
                          isDisabled={!canEditUser}
                          options={schoolList
                            ?.filter((f: any) => f.id !== schoolId)
                            ?.map((filter: any) => ({ value: filter.id, label: filter.name }))}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              ) : (
                <></>
              )}
            </Row>
            {canEditUser && (
              <Button className="my-4" color="primary" type="submit" disabled={isDisabled}>
                Save
              </Button>
            )}
          </div>
        </Form>
      </Row>
      <EmployeeSettingsModal
        isOpen={settingsModal}
        toggleModal={() => {
          setSettingsModal(!settingsModal);
          listTitles();
          listDepartments();
        }}
      />
    </>
  );
};

export default EmployeeDetailsTab;
