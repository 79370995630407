import { FormGroup, Label, Input, Row, Col } from 'reactstrap';

const PreEmploymentHistoryForm = ({
  handleInputChange,
  employmentHistory,
}: {
  handleInputChange: (index: any, e: any) => void;
  employmentHistory: any;
}) => {
  return (
    <>
      <hr />
      <br />
      <Row form>
        <Col md={12}>
          <h2 className="text-primary">Employment History and Reference Check</h2>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="applicantName">Name of Applicant: Last, First Middle</Label>
            <Input
              type="text"
              name="applicantName"
              id="applicantName"
              value={employmentHistory.applicantName}
              onChange={(e) => handleInputChange(0, e)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="positionAppliedFor">Position:</Label>
            <Input
              type="text"
              name="positionAppliedFor"
              id="positionAppliedFor"
              value={employmentHistory.positionAppliedFor}
              onChange={(e) => handleInputChange(0, e)}
            />
          </FormGroup>
        </Col>
      </Row>

      <p>
        Pursuant to Chapter 402 of the Florida Statute, background screening for purposes of childcare also includes an
        employment history check. The purpose of an employment history check is to validate the person's employment
        history (dates and employers) and determine the person's work ethic and childcare experience (if any). Provide
        the following information about your previous employment.
      </p>
      <ul>
        <li>In chronological order (most recent job first)</li>
        <li>List jobs within the last five-year period</li>
      </ul>

      {[0, 1, 2, 3].map((index) => (
        <div key={index}>
          <h4>
            {index + 1}) {index === 0 ? 'Current or Most Recent Employers' : 'Next Most Recent Employers'}
          </h4>
          <FormGroup>
            <Label for={`placeOfEmployment-${index}`}>Place of Employment:</Label>
            <Input
              type="text"
              name="placeOfEmployment"
              id={`placeOfEmployment-${index}`}
              value={employmentHistory.history[index].placeOfEmployment}
              onChange={(e) => handleInputChange(index, e)}
            />
          </FormGroup>

          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for={`phone-${index}`}>Phone:</Label>
                <Input
                  type="text"
                  name="phone"
                  id={`phone-${index}`}
                  value={employmentHistory.history[index].phone}
                  onChange={(e) => handleInputChange(index, e)}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col md={4}>
              <FormGroup>
                <Label for={`address-${index}`}>Address:</Label>
                <Input
                  type="text"
                  name="address"
                  id={`address-${index}`}
                  value={employmentHistory.history[index].address}
                  onChange={(e) => handleInputChange(index, e)}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for={`city-${index}`}>City:</Label>
                <Input
                  type="text"
                  name="city"
                  id={`city-${index}`}
                  value={employmentHistory.history[index].city}
                  onChange={(e) => handleInputChange(index, e)}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for={`state-${index}`}>State:</Label>
                <Input
                  type="text"
                  name="state"
                  id={`state-${index}`}
                  value={employmentHistory.history[index].state}
                  onChange={(e) => handleInputChange(index, e)}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for={`datesOfEmploymentFrom-${index}`}>Dates of Employment: From</Label>
                <Input
                  type="date"
                  name="datesOfEmploymentFrom"
                  id={`datesOfEmploymentFrom-${index}`}
                  value={employmentHistory.history[index].datesOfEmploymentFrom}
                  onChange={(e) => handleInputChange(index, e)}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for={`datesOfEmploymentUntil-${index}`}>Until:</Label>
                <Input
                  type="date"
                  name="datesOfEmploymentUntil"
                  id={`datesOfEmploymentUntil-${index}`}
                  value={employmentHistory.history[index].datesOfEmploymentUntil}
                  onChange={(e) => handleInputChange(index, e)}
                />
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <Label for={`positionHeld-${index}`}>Position Held:</Label>
            <Input
              type="text"
              name="positionHeld"
              id={`positionHeld-${index}`}
              value={employmentHistory.history[index].positionHeld}
              onChange={(e) => handleInputChange(index, e)}
            />
          </FormGroup>

          <FormGroup>
            <Label for={`supervisor-${index}`}>Supervisor:</Label>
            <Input
              type="text"
              name="supervisor"
              id={`supervisor-${index}`}
              value={employmentHistory.history[index].supervisor}
              onChange={(e) => handleInputChange(index, e)}
            />
          </FormGroup>

          <FormGroup>
            <Label for={`reasonForLeaving-${index}`}>Reason for Leaving:</Label>
            <Input
              type="text"
              name="reasonForLeaving"
              id={`reasonForLeaving-${index}`}
              value={employmentHistory.history[index].reasonForLeaving}
              onChange={(e) => handleInputChange(index, e)}
            />
          </FormGroup>

          <FormGroup>
            <Label for={`jobDescription-${index}`}>Job Description:</Label>
            <Input
              type="textarea"
              name="jobDescription"
              id={`jobDescription-${index}`}
              value={employmentHistory.history[index].jobDescription}
              onChange={(e) => handleInputChange(index, e)}
            />
          </FormGroup>
        </div>
      ))}
    </>
  );
};

export default PreEmploymentHistoryForm;
