import moment from 'moment';

const glue = '|';
const PayRatesListColumns = ({
  maxDescriptionLength,
  setSelected,
}: {
  maxDescriptionLength: number;
  setSelected: any;
}) => [
  {
    name: 'Date',
    grow: 1,
    cell: (row: any) => moment(row.createdAt).format('MM/DD/YYYY'),
  },
  {
    name: 'Pay Rate',
    grow: 1,
    cell: (row: any) => '$' + parseFloat(row.payRate).toFixed(2),
  },
  {
    name: 'Payment increase',
    grow: 1,
    cell: (row: any) => (row.percentIncrease ? parseFloat(row.percentIncrease).toFixed(2) + '%' : '-'),
  },
  {
    name: 'Reason',
    grow: 1,
    cell: (row: any) => row.reason,
  },
  {
    name: 'Description',
    grow: 2,
    cell: (row: any) => (
      <a href="#" onClick={() => setSelected(row.id)}>
        {row.description
          .map((d: any) => d.text)
          .join(glue)
          .substring(0, maxDescriptionLength) +
          (row.description.map((d: any) => d.text).join(glue).length > maxDescriptionLength ? '...' : '')}
      </a>
    ),
  },
  {
    name: 'Created By',
    grow: 1,
    cell: (row: any) => row.creator?.firstName + '' + row.creator?.lastName,
  },
  {
    name: 'Status',
    grow: 1,
    cell: (row: any) => (row.deletedAt ? 'Changed' : 'Current'),
  },
];
export default PayRatesListColumns;
