import http from '../helpers/http';

const BankingAccounts = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    showSecretNumber: async (id: string, type: string) => {
      const result = await http.get(`/banking-accounts/${id}/${type}`, {
        headers,
      });
      return result.data;
    },
    list: async (employeeId: string) => {
      const result = await http.get(`/banking-accounts/${employeeId}`, {
        headers,
      });
      return result.data;
    },
    create: async (body: any) => {
      const result = await http.post(`/banking-accounts`, body, { headers });
      return result.data;
    },
    delete: async (id: string) => {
      const result = await http.delete(`/banking-accounts/${id}`, {
        headers,
      });
      return result.data;
    },
    uploadFile: async (employeeId: string, body: FormData) => {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': token,
        },
      };
      const result = await http.patch(`/banking-accounts/${employeeId}/document`, body, headers);
      return result.data;
    },
  };
};

export default BankingAccounts;
