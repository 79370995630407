import { confirmAlert as reactConfirmAlert } from 'react-confirm-alert';
import { Container, Button, Row, Col, Card, CardBody, CardTitle } from 'reactstrap';

type ClassAlertProps = {
  title: string;
  messageType: string;
  id?: string;
  message?: string;
  onSubmit: (param?: any) => void;
  label?: string;
  children?: any;
};

const confirmAlertWithForm = ({ title, messageType, id, message, onSubmit, label, children }: ClassAlertProps) => {
  const validMessageTypes = [
    { messageType: 'SAVE', text: 'save' },
    { messageType: 'EDIT', text: 'edit' },
    { messageType: 'DELETE', text: 'delete' },
  ];
  const alertMessageType = `${validMessageTypes.find((m) => m.messageType === messageType)?.text || 'save'}`;
  const itemName = `'${label ? label : id ? id : ''}'`;
  const alertMessage = message ? message : `Are you sure to ${alertMessageType} item ${itemName}?`;

  return reactConfirmAlert({
    customUI: ({ onClose }) => {
      return (
        <Container fluid>
          <Card>
            <CardBody>
              <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                {title}
              </CardTitle>
              <Row className="mt-3">
                <Col md="12 text-center">
                  <span className="h2 font-weight-bold mb-0">{alertMessage}</span>
                </Col>
              </Row>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  onSubmit(e);
                  onClose();
                }}
              >
                {children && children}
                <Row className="mt-5">
                  <Col md="6 text-center" xs="6">
                    <Button color="primary" type="submit" size="lg" style={{ minWidth: '100px' }}>
                      Yes
                    </Button>
                  </Col>
                  <Col md="6 text-center" xs="6">
                    <Button
                      color="danger"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        onClose();
                      }}
                      size="lg"
                      style={{ minWidth: '100px' }}
                    >
                      No
                    </Button>
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Container>
      );
    },
  });
};

export default confirmAlertWithForm;
