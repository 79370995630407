import { Row, Col, FormGroup, Label, Input } from 'reactstrap';

const PreEmploymentEmergencyForm = ({ handleChange, formData }: { handleChange: (e: any) => void; formData: any }) => {
  return (
    <>
      <h4 className="mt-3">Emergency Contact Information:</h4>

      <FormGroup>
        <Label for="emergencyName">Name:</Label>
        <Input
          type="text"
          name="emergencyName"
          id="emergencyName"
          value={formData.emergencyName}
          onChange={handleChange}
        />
      </FormGroup>

      <FormGroup>
        <Label for="emergencyRelationship">Relationship:</Label>
        <Input
          type="text"
          name="emergencyRelationship"
          id="emergencyRelationship"
          value={formData.emergencyRelationship}
          onChange={handleChange}
        />
      </FormGroup>

      <FormGroup>
        <Label for="emergencyAddress">Address:</Label>
        <Input
          type="text"
          name="emergencyAddress"
          id="emergencyAddress"
          value={formData.emergencyAddress}
          onChange={handleChange}
        />
      </FormGroup>

      <Row form>
        <Col md={4}>
          <FormGroup>
            <Label for="emergencyPhoneH">Phone Number:</Label>
            <Input
              type="text"
              name="emergencyPhoneH"
              id="emergencyPhoneH"
              value={formData.emergencyPhoneH}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default PreEmploymentEmergencyForm;
