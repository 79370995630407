/* eslint-disable max-lines */
import {
  Container,
  Badge,
  Card,
  Row,
  Col,
  Input,
  Button,
  Modal,
  Form,
  Label,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import CommonHeader from '../../../components/Headers/CommonHeader';
import EmployeeService from '../../../services/Employee';
import { PagedMetaInterface, createPagedMeta } from '../../../entities/PagedMeta';
import HireAgreementsService from '../../../services/HireAgreements';
import EmployeeTitlesService from '../../../services/EmployeeTitles';
import EmployeeDepartmentsService from '../../../services/EmployeeDepartments';
import SchoolService from '../../../services/School';
import PermissionGroupsService from '../../../services/PermissionGroups';
import DataTable from '../../../components/Core/DataTableWithoutHeader';
import moment from 'moment';
import ReactDatetime from 'react-datetime';
import Pto from '../../../services/Pto';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { toLocaleFormatSlash } from '../../../helpers/dateFormat';
import EmployeeTerminate from '../../../services/EmployeeTerminate';
import NumberFormat from 'react-number-format';
import confirmAlert from '../../../helpers/confirmAlert';
import ManagementOfferLetter from './ManagementOfferLetter';
import StaffOfferLetter from './StaffOfferLetter';
import InputMask from 'react-input-mask';
import TerminatedEmployeeForm from './TerminatedEmployeeForm';
import RevokeOfferForm from './RevokeOfferForm';
import RevokeTerminationForm from './RevokeTerminationForm';
import RehireForm from './RehireForm';
import { Link, useHistory } from 'react-router-dom';
import EmploymentDocumentsForm from './EmploymentDocumentsForm';
import Messaging from '../../../services/Messaging';
import { isMobile } from 'react-device-detect';
import ModalProfile from '../../../components/Profile/ModalProfile';
import { PayRatesForm } from '../../../components/Employee';

const DirectoryList = () => {
  const { token, currentSchool, currentFranchisee, user }: any = useContext(AuthContext);
  const history = useHistory();

  const validPermissionGroups = [
    {
      name: 'Accounting',
      label: 'Accounting',
    },
    {
      name: 'Director',
      label: 'Director',
    },
    {
      name: 'Staff',
      label: 'Staff',
    },
    {
      name: 'Teachers',
      label: 'Teacher',
    },
    {
      name: 'EducationDirectorAdminAssistant',
      label: 'Education Director / Admin Assistant',
    },
    {
      name: 'UpperManagement',
      label: 'Upper Management',
    },
  ];

  let columns: any[] = [
    {
      name: 'Name',
      grow: 3,
      route: (row: any) => `/users/${row.user.id}/profile`,
      cell: (row: any) => (
        <Link to={`/users/${row.user.id}/profile`}>
          <div className="d-flex align-items-center">
            <div style={{ width: '35px', height: '35px', borderRadius: '50%', overflow: 'hidden' }}>
              <img
                src={row.user.picture}
                alt="User Profile"
                className="img-fluid"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </div>
            <span className="ml-2">{`${row.user.firstName || '-'} ${row.user.lastName || '-'}`}</span>
          </div>
        </Link>
      ),
    },
  ];

  if (!isMobile) {
    columns = [
      ...columns,
      ...[
        {
          name: 'Status',
          selector: (row: any) => row.terminationDate,
          grow: 1,
          cell: (row: any) => (
            <Badge
              color=""
              className="badge-dot badge-lg"
              style={{ fontSize: '0.8125rem', verticalAlign: 'middle', lineHeight: 'normal' }}
            >
              <>
                {row.status === 'ACTIVE' && <i className={`bg-success mr-2`} style={{ verticalAlign: 'baseline' }} />}
                {row.status === 'TERMINATED' && (
                  <i className={`bg-danger mr-2`} style={{ verticalAlign: 'baseline' }} />
                )}
                {row.status === 'BLOCKED' && <i className={`bg-pink mr-2`} style={{ verticalAlign: 'baseline' }} />}
                {row.status === 'LOA' && <i className={`bg-teal mr-2`} style={{ verticalAlign: 'baseline' }} />}
                {(row.status === 'HIRED' || row.status === 'PENDING') && (
                  <i className={`bg-yellow mr-2`} style={{ verticalAlign: 'baseline' }} />
                )}
                {row.status}
              </>
            </Badge>
          ),
        },
        {
          name: 'Type',
          selector: (row: any) => row.workday,
          grow: 1,
          cell: (row: any) => row.workday,
        },
        {
          name: 'Title',
          selector: (row: any) => row.title.name,
          grow: 1,
          cell: (row: any) => row.title.name,
        },
        {
          name: 'Department',
          selector: (row: any) => row.department.name,
          grow: 1,
          cell: (row: any) => row.department.name,
        },
        {
          name: 'Supervisor',
          selector: (row: any) => row.supervisor.name,
          grow: 1,
          cell: (row: any) =>
            row?.supervisor?.name ? (
              row?.supervisor?.name?.split(' ').map((item: string) => {
                return (
                  <>
                    {item}
                    <br />
                  </>
                );
              })
            ) : (
              <>-</>
            ),
        },
        {
          name: 'Start Date',
          selector: (row: any) => row.startDate,
          grow: 1,
          cell: (row: any) => toLocaleFormatSlash(row.startDate),
        },
        {
          name: 'Termination Date',
          selector: (row: any) => row.terminationDate,
          grow: 2,
          cell: (row: any) => (row?.terminationDate ? toLocaleFormatSlash(row.terminationDate) : '-'),
        },
        {
          name: 'Paperwork',
          selector: (row: any) => row.paperwork,
          grow: 1,
          cell: (row: any) => (
            <i
              className={`fas ${row?.paperwork ? 'fa-check-circle' : 'fa-ban'}`}
              style={{ color: `${row?.paperwork ? 'green' : 'red'}` }}
            />
          ),
        },
        {
          name: '',
          grow: 1,
          cell: (row: any) => (
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav style={{ paddingLeft: '0' }}>
                <Button style={{ border: 'none', boxShadow: 'none', background: 'none' }}>
                  <FontAwesomeIcon icon={faEllipsisVertical} />
                </Button>
              </DropdownToggle>
              {row.status === 'ACTIVE' && <i className={`bg-success mr-2`} style={{ verticalAlign: 'baseline' }} />}
              {row.status === 'TERMINATED' && <i className={`bg-danger mr-2`} style={{ verticalAlign: 'baseline' }} />}
              {(row.status === 'HIRED' || row.status === 'PENDING') && (
                <i className={`bg-yellow mr-2`} style={{ verticalAlign: 'baseline' }} />
              )}
              <DropdownMenu className="dropdown-menu-arrow" right>
                {row.status === 'ACTIVE' && !row?.terminationDate && (
                  <>
                    <DropdownItem onClick={(e) => handleRemoveAccess(e, row)}>
                      <span>Remove Access</span>
                    </DropdownItem>
                    <DropdownItem onClick={() => handleTerminateUserOpen(row)}>
                      <span>Terminate</span>
                    </DropdownItem>
                    <DropdownItem onClick={(e) => handlePutOnLeave(e, row)}>
                      <span>Put on leave</span>
                    </DropdownItem>
                    <DropdownItem onClick={(e) => handleUpdatePayment(e, row)}>
                      <span>Update Payment</span>
                    </DropdownItem>
                  </>
                )}
                {row.status === 'LOA' && (
                  <>
                    <DropdownItem onClick={() => handleTerminateUserOpen(row)}>
                      <span>Terminate</span>
                    </DropdownItem>
                    <DropdownItem onClick={(e) => handleEndPutOnLeave(e, row)}>
                      <span>End LOA</span>
                    </DropdownItem>
                  </>
                )}

                {row.status === 'ACTIVE' && row?.terminationDate && (
                  <>
                    <DropdownItem onClick={(e) => handleRemoveAccess(e, row)}>
                      <span>Remove Access</span>
                    </DropdownItem>
                    <DropdownItem onClick={() => handleRevokeTerminationOpen(row)}>
                      <span>Revoke Termination</span>
                    </DropdownItem>
                  </>
                )}

                {(row.status === 'PENDING' || row.status === 'HIRED') && (
                  <DropdownItem onClick={() => handleRevokeUserOpen(row)}>
                    <span>Revoke</span>
                  </DropdownItem>
                )}

                {row.status === 'PENDING' && (
                  <DropdownItem onClick={(e: any) => handleResendEmail(e, row)}>
                    <span>Resend Email</span>
                  </DropdownItem>
                )}

                {row.status === 'TERMINATED' && (
                  <>
                    <DropdownItem onClick={() => handleRevokeTerminationOpen(row)}>
                      <span>Revoke Termination</span>
                    </DropdownItem>
                    <DropdownItem onClick={() => handleRehireOpen(row)}>
                      <span>Rehire</span>
                    </DropdownItem>
                  </>
                )}

                {row.status === 'BLOCKED' && (
                  <>
                    <DropdownItem onClick={() => handleTerminateUserOpen(row)}>
                      <span>Terminate</span>
                    </DropdownItem>
                  </>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
        },
      ],
    ];
  }

  const [data, setData] = useState([] as any[]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [pagedMeta, setPagedMeta] = useState(createPagedMeta());
  const [school, setSchool] = useState<any>();

  const [isNewEmployeeFormOpen, setIsNewEmployeeFormOpen] = useState(false);
  const [newEmployeeFormStep, setNewEmployeeFormStep] = useState(0);
  const [hireAgreementsList, setHireAgreementsList] = useState([] as any[]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [classification, setClassification] = useState('');
  const [hireDate, setHireDate] = useState(moment().format('MM/DD/yyyy'));
  const [isIncludeOfferLetter, setIsIncludeOfferLetter] = useState<boolean | null>();
  const [hiringAgreements, setHiringAgreements] = useState<any[]>([]);
  const [employmentEligibilityDocs, setEmploymentEligibilityDocs] = useState<any[]>(['I9_ENGLISH']);
  const [taxDocs, setTaxDocs] = useState<any[]>(['W4']);
  const [isAddToPayroll, setIsAddToPayroll] = useState<boolean | null>();
  const [isCollectTaxInformation, setIsCollectTaxInformation] = useState<boolean | null>();
  const [employees, setEmployees] = useState<any>([]);
  const [departmentList, setDepartmentList] = useState([{}]);
  const [offerLetter, setOfferLetter] = useState('');
  const [ptoPolices, setPtoPolices] = useState([] as any[]);
  const [titleList, setTitleList] = useState([{}]);
  const [titleId, setTitleId] = useState('');
  const [groupsList, setGroupsList] = useState([] as any[]);
  const [groupId, setGroupId] = useState('');
  const [departmentId, setDepartmentId] = useState('');
  const [supervisorId, setSupervisorId] = useState('');
  const [paymentFrequency, setPaymentFrequency] = useState<string>();
  const [amount, setAmount] = useState<string>();
  const [policy, setPolicy] = useState<string>();
  const [isSign, setIsSign] = useState(false);
  const [signature, setSignature] = useState('');

  const [isTerminatedEmployeeFormOpen, setIsTerminatedEmployeeFormOpen] = useState(false);
  const [terminatedEmployeeStep, setTerminatedEmployeeStep] = useState(0);
  const [terminationUser, setTerminationUser] = useState<any>();
  const [terminationDate, setTerminationDate] = useState(moment().format('MM/DD/YYYY'));
  const [terminationCategory, setTerminationCategory] = useState('');
  const [willBeRehired, setWillBeRehired] = useState<boolean | null>(null);
  const [terminationReason, setTerminationReason] = useState('');
  const [terminationNotes, setTerminationNotes] = useState('');
  const [terminationUserEmail, setTerminationUserEmail] = useState('');
  const [terminationNameToConfirm, setTerminationNameToConfirm] = useState('');

  const [isRevokeHireOpen, setIsRevokeHireOpen] = useState(false);
  const [revokeNameToConfirm, setRevokeNameToConfirm] = useState('');
  const [revokeHireUser, setRevokeHireUser] = useState<any>();

  const [isRevokeTerminationOpen, setIsRevokeTerminationOpen] = useState(false);
  const [revokeTerminationUser, setRevokeTerminationUser] = useState<any>();

  const [isRehireOpen, setIsRehireOpen] = useState(false);
  const [rehireUser, setRehireUser] = useState<any>();
  const [confirmRehire, setConfirmRehire] = useState(false);
  const [i9Option, setI9Option] = useState('');
  const [otherDocs, setOtherDocs] = useState<any[]>(['']);

  const [modalUpdatePayment, setModalUpdatePayment] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any | null>(null);

  useEffect(() => {
    if (terminationUser) {
      setTerminationUserEmail(terminationUser?.user?.email);
    }

    handleResetTerminationInfo();
  }, [terminationUser]);

  const handleResetTerminationInfo = () => {
    setTerminationDate(moment().format('MM/DD/YYYY'));
    setTerminationCategory('');
    setWillBeRehired(null);
    setTerminationReason('');
    setTerminationNameToConfirm('');
    setTerminationNotes('');
  };

  const handleTerminateUserOpen = async (user: any) => {
    if (user.id === terminationUser?.id) {
      toogleTerminatedEmployeeForm();
      return;
    }

    setTerminationUser(user);
    setTerminatedEmployeeStep(0);
    toogleTerminatedEmployeeForm();
  };

  const handleRevokeUserOpen = async (user: any) => {
    if (user.id === terminationUser?.id) {
      toogleRevokeHire();
      return;
    }

    setRevokeNameToConfirm('');
    setRevokeHireUser(user);
    toogleRevokeHire();
  };

  const handleOpenByParams = async (data: any[]) => {
    const queryParams = new URLSearchParams(window.location.search);
    const idParam = `${queryParams.get('id')}`;
    const typeParam = `${queryParams.get('type')}`;
    if (!idParam || !typeParam) return;
    const user = data.find((d) => d?.id === idParam);
    if (user && typeParam === 'revoke') {
      handleRevokeTerminationOpen(user);
    }
    if (user && typeParam === 'rehire') {
      handleRehireOpen(user);
    }
    setTimeout(() => {
      history.replace({
        search: '',
      });
    }, 2000);
  };

  const handleRevokeTerminationOpen = async (user: any) => {
    setRevokeTerminationUser(user);
    toogleRevokeTermination();
  };

  const handleRehireOpen = async (user: any) => {
    setRehireUser(user);
    toogleRehire();
  };

  const handleResendEmail = async (e: React.FormEvent, user: any) => {
    if (user?.id) {
      e.preventDefault();
      const id = user?.id || '';
      return confirmAlert({
        title: 'Confirm',
        messageType: 'EDIT',
        message: 'Are you sure you want to resend the email?',
        id,
        onClickYes: () => resendEmail(id),
      });
    }
  };

  const handleUpdatePayment = async (e: React.FormEvent, user: any) => {
    if (user?.id) {
      e.preventDefault();
      const id = user?.id || '';
      return confirmAlert({
        title: 'Confirm',
        messageType: 'EDIT',
        message: 'Are you sure you want to update payment?',
        id,
        onClickYes: () => {
          setSelectedUser({ ...{ employeeId: user.id }, ...user.user });
          setModalUpdatePayment(!modalUpdatePayment);
        },
      });
    }
  };

  const handlePutOnLeave = async (e: React.FormEvent, user: any) => {
    if (user?.id) {
      e.preventDefault();
      const id = user?.id || '';
      return confirmAlert({
        title: 'Confirm',
        messageType: 'EDIT',
        message: 'Are you sure you want to put on leave?',
        id,
        onClickYes: () => putOnLeave(id),
      });
    }
  };

  const handleEndPutOnLeave = async (e: React.FormEvent, user: any) => {
    if (user?.id) {
      e.preventDefault();
      const id = user?.id || '';
      return confirmAlert({
        title: 'Confirm',
        messageType: 'EDIT',
        message: 'Are you sure you want to end LOA?',
        id,
        onClickYes: () => endPutOnLeave(id),
      });
    }
  };

  const handleRemoveAccess = async (e: React.FormEvent, user: any) => {
    if (user?.id) {
      e.preventDefault();
      const id = user?.id || '';
      return confirmAlert({
        title: 'Confirm',
        messageType: 'EDIT',
        message: 'Are you sure you want to remove this access?',
        id,
        onClickYes: () => removeAccess(id),
      });
    }
  };

  const revokeOffer = async () => {
    const id = revokeHireUser?.id || '';
    await EmployeeService(token).revokeNewHire(id);
    toast.success('Saved successfully!');
    setIsRevokeHireOpen(false);
    loadList();
  };

  const resendEmail = async (id: string) => {
    await EmployeeService(token).resendNewHire(id);
    toast.success('Sent successfully!');
  };

  const removeAccess = async (id: string) => {
    await EmployeeService(token).removeAccess(id);
    toast.success('Saved successfully!');
    loadList();
  };

  const putOnLeave = async (id: string) => {
    await EmployeeService(token).putOnLeave(id);
    toast.success('Saved successfully!');
    loadList();
  };

  const endPutOnLeave = async (id: string) => {
    await EmployeeService(token).endPutOnLeave(id);
    toast.success('Saved successfully!');
    loadList();
  };

  const toogleNewEmployeeForm = () => setIsNewEmployeeFormOpen(!isNewEmployeeFormOpen);

  const toogleTerminatedEmployeeForm = () => setIsTerminatedEmployeeFormOpen(!isTerminatedEmployeeFormOpen);

  const toogleRevokeHire = () => setIsRevokeHireOpen(!isRevokeHireOpen);

  const toogleRevokeTermination = () => {
    setIsRevokeTerminationOpen(!isRevokeTerminationOpen);
    loadList();
  };

  const toogleRehire = () => setIsRehireOpen(!isRehireOpen);

  const handleSubmitTerminatedUser = async (event: React.FormEvent) => {
    event.preventDefault();

    if (terminatedEmployeeStep === 0) {
      setTerminatedEmployeeStep(1);
      return;
    }

    try {
      const data: any = {
        employeeId: terminationUser?.id,
        schoolId: currentSchool.value,
        lastWorkDay: terminationDate,
        category: terminationCategory,
        willBeRehired,
        reason: terminationReason,
        email: terminationUserEmail,
      };

      if (terminationNotes) {
        data.notes = terminationNotes;
      }

      await EmployeeTerminate(token).create(data);

      handleResetTerminationInfo();
      setTerminatedEmployeeStep(0);
      toogleTerminatedEmployeeForm();
      loadList();
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Request error');
      setLoading(false);
    }
  };

  const loadRehire = async () => {
    if (!rehireUser) return;
    setNewEmployeeFormStep(0);
    setFirstName(rehireUser?.user?.firstName || '');
    setLastName(rehireUser?.user?.lastName || '');
    setEmail(rehireUser?.user?.email || '');
    setPhone(rehireUser?.user?.phone || '');
    setClassification(rehireUser?.workday || '');
    setOfferLetter(rehireUser?.offerLetter || '');
    setHireDate(moment().format('MM/DD/yyyy'));
    setIsIncludeOfferLetter(null);
    setHiringAgreements([]);
    setIsAddToPayroll(null);
    setIsCollectTaxInformation(null);
    setTitleId(rehireUser?.titleId || '');
    setGroupId(rehireUser?.user?.groupId || '');
    setDepartmentId(rehireUser?.departmentId || '');
    setSupervisorId(rehireUser?.supervisorId || '');
    setPaymentFrequency(rehireUser?.paymentFrequency || '');
    setAmount(rehireUser?.payRate || 0);
    setPolicy(rehireUser?.ptoPolicyId || '');
    toogleNewEmployeeForm();
  };

  useEffect(() => {
    loadList();
  }, [search]);

  useEffect(() => {
    loadList();
  }, [isNewEmployeeFormOpen]);

  useEffect(() => {
    if (!isRehireOpen) {
      setConfirmRehire(false);
    }
  }, [isRehireOpen]);

  useEffect(() => {
    if (confirmRehire) {
      loadRehire();
    }
  }, [confirmRehire]);

  useEffect(() => {
    listData();
    listEmployees();
    listDepartments();
    listTitles();
    listPtoPolices();
    listGroups();
  }, []);

  useEffect(() => {
    listData();
    listEmployees();
    listDepartments();
    listTitles();
    listPtoPolices();
    listGroups();
    loadList();
  }, [currentSchool, currentFranchisee]);

  const listData = async () => {
    try {
      const result = await HireAgreementsService(token).list();

      setHireAgreementsList(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No data Found!');
    }
  };

  const listPtoPolices = async () => {
    try {
      const result = await Pto(token).ptoPolicyList();
      setPtoPolices(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No data Found!');
    }
  };

  const listTitles = async () => {
    try {
      const result = await EmployeeTitlesService(token).list();
      setTitleList(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No titles found');
    }
  };

  const listEmployees = async () => {
    const idSchool = currentSchool.value;
    // const { data } = await EmployeeService(token).listEmployeesBySchool(idSchool);
    const { data } = await Messaging(token).listSelectStaff(idSchool);
    setEmployees(data);
  };

  const listDepartments = async () => {
    try {
      const result = await EmployeeDepartmentsService(token).list();
      setDepartmentList(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No departments found');
    }
  };

  const listGroups = async () => {
    try {
      const result = await PermissionGroupsService(token).list();
      const valids = validPermissionGroups.map((v) => v.name);
      const filtered = result.filter((v: any) => valids.includes(v.name));
      const groupList: { id: string; name: string }[] = [];
      for (const r of filtered) {
        const valid = validPermissionGroups.find((v) => v.name === r.name);
        groupList.push({
          id: r?.id || '',
          name: valid?.label || '',
        });
      }
      setGroupsList(groupList || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No permission groups found');
    }
  };

  const loadList = async () => {
    try {
      setLoading(true);
      const result = await EmployeeService(token).listEmployeesBySchool(currentSchool.value, search);
      const data = result.data;
      setData(data);
      handleOpenByParams(data);
      const meta: PagedMetaInterface = result.meta;
      if (meta) {
        setPagedMeta(meta);
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error fetching list');
      return toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    listSelectSchool();
  }, []);

  useEffect(() => {
    const group = groupsList.find((g) => g.id === groupId);
    if (group?.name === 'Director') {
      return setPaymentFrequency('annually');
    }
    setPaymentFrequency('hourly');
  }, [groupId]);

  const listSelectSchool = async () => {
    const result = await SchoolService(token).show(currentSchool.value);
    setSchool(result.data || {});
  };

  const handleFindTitleName = () => {
    const titleName: any = titleList.find((value: any) => value.id === titleId);

    return titleName?.name;
  };

  const handleFindSupervisorName = () => {
    const supervisorName: any = employees.find((value: any) => value.userId === supervisorId);
    return `${supervisorName?.user?.firstName}  ${supervisorName?.user?.lastName}`;
  };

  const changeEmploymentEligibility = (doc: string) => {
    const isChecked = employmentEligibilityDocs.includes(doc);
    const newEmploymentEligibilityDocs = [...employmentEligibilityDocs];

    if (isChecked) {
      setEmploymentEligibilityDocs(newEmploymentEligibilityDocs.filter((value: string) => value !== doc));
    } else {
      newEmploymentEligibilityDocs.push(doc);
      setEmploymentEligibilityDocs(newEmploymentEligibilityDocs);
    }
  };

  const changeOtherDocs = (doc: string) => {
    const isChecked = otherDocs.includes(doc);
    const newOtherDocs = [...otherDocs];

    if (isChecked) {
      setOtherDocs(newOtherDocs.filter((value: string) => value !== doc));
    } else {
      newOtherDocs.push(doc);
      setOtherDocs(newOtherDocs);
    }
  };

  const selectAllDocs = () => {
    setEmploymentEligibilityDocs(['TWO_DOCS_FOR_PROOF']);
    setTaxDocs(['W4']);
    setHiringAgreements(hireAgreementsList.map((agreement) => agreement?.id));
  };

  const handleSubmitForm = async (event: React.FormEvent) => {
    event.preventDefault();

    if (newEmployeeFormStep === 0) {
      if (email !== confirmEmail) {
        toast.error('Email must be equal to confirm email');
        return;
      }
    }

    if (newEmployeeFormStep !== 4) {
      setNewEmployeeFormStep(newEmployeeFormStep + 1);
      return;
    }

    if (!isSign || !signature) {
      toast.error('Your signature is required!');
      return;
    }

    const data: any = {
      employeeId: rehireUser?.id || undefined,
      firstName,
      lastName,
      email,
      phone,
      schoolId: currentSchool.value,
      franchiseeId: currentFranchisee.value,
      titleId,
      groupId,
      departmentId,
      supervisorId,
      offerLetter,
      ptoPolicyId: policy,
      startDate: hireDate,
      workday: classification,
      paymentFrequency: paymentFrequency,
      payRate: String(amount)?.replace('$', '')?.replace(',', ''),
      hiringAgreements,
      employmentEligibilityDocs: [...employmentEligibilityDocs, ...[i9Option]],
      taxDocs,
      otherDocs,
    };

    if (classification === 'FT' && paymentFrequency === 'hourly') {
      data.hoursPerWeek = 40;
    }

    if (classification === 'PT' && paymentFrequency === 'hourly') {
      data.hoursPerWeek = 32;
    }

    if (classification === 'FT' && paymentFrequency === 'annually') {
      data.hoursPerWeek = 45;
    }

    if (classification === 'Sub') {
      data.hoursPerWeek = 40;
    }

    if (isIncludeOfferLetter) {
      data.imageUrl = school?.franchisee?.imageUrl;
      data.senderName = `${user.firstName} ${user.lastName}`;
      data.senderPhone = user?.phone || '';
      data.currentSchool = currentSchool.label;
      data.positionName = `${handleFindTitleName()}`;
      data.signature = signature;
      data.supervisorName = `${handleFindSupervisorName()}`;
    }

    try {
      await EmployeeService(token).createNewHire(data);
      toast.success('Saved successfully!');
      setNewEmployeeFormStep(0);
      setFirstName('');
      setLastName('');
      setEmail('');
      setPhone('');
      setClassification('');
      setHireDate(moment().format('MM/DD/yyyy'));
      setIsIncludeOfferLetter(null);
      setHiringAgreements([]);
      setIsAddToPayroll(null);
      setIsCollectTaxInformation(null);
      setTitleId('');
      setGroupId('');
      setDepartmentId('');
      setSupervisorId('');
      setPaymentFrequency('');
      setAmount('');
      setPolicy('');
      setSignature('');
      setIsSign(false);
      setConfirmRehire(false);
      setRehireUser({});
      setHiringAgreements([]);
      toogleNewEmployeeForm();
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Sorry, error on save.');
    }
  };

  return (
    <>
      <CommonHeader />
      <ModalProfile
        className="modal-dialog-centered"
        isOpen={modalUpdatePayment}
        toggle={() => {
          setModalUpdatePayment(!modalUpdatePayment);
        }}
        size={'xs'}
        user={selectedUser}
      >
        <PayRatesForm
          id={selectedUser?.employeeId}
          onSubmit={(_) => {
            setModalUpdatePayment(!modalUpdatePayment);
          }}
        />
      </ModalProfile>
      <Modal
        className="modal-dialog-centered"
        isOpen={isNewEmployeeFormOpen}
        toggle={toogleNewEmployeeForm}
        size={'xl'}
      >
        <div className="modal-header">
          <h4 className="modal-title">Add a New Hire</h4>
          <div onClick={toogleNewEmployeeForm} className="d-flex p-1 cursor-pointer">
            <i className="fas fa-times" />
          </div>
        </div>
        <div className="modal-body">
          <div className="px-2">
            {newEmployeeFormStep === 0 && (
              <Form onSubmit={handleSubmitForm}>
                <Row className="d-flex aligm-items-center">
                  <Col lg="3">
                    <label className="form-control-label">First Name:</label>
                  </Col>
                  <Col lg="9">
                    <Input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                  </Col>
                </Row>

                <Row className="d-flex aligm-items-center mt-4">
                  <Col lg="3">
                    <label className="form-control-label">Last Name:</label>
                  </Col>
                  <Col lg="9">
                    <Input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                  </Col>
                </Row>

                <Row className="d-flex aligm-items-center mt-4">
                  <Col lg="3">
                    <label className="form-control-label">Email Address:</label>
                  </Col>
                  <Col lg="9">
                    <Input
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      autoComplete="off"
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                    {email === 'john@arb.systems' ? (
                      <label className="text-sm text-danger">This email already exists for an active user.</label>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>

                <Row className="d-flex aligm-items-center mt-4">
                  <Col lg="3">
                    <label className="form-control-label">Confirm Email:</label>
                  </Col>
                  <Col lg="9">
                    <Input
                      type="text"
                      value={confirmEmail}
                      onChange={(e) => setConfirmEmail(e.target.value)}
                      required
                      autoComplete="off"
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </Col>
                </Row>

                <Row className="d-flex aligm-items-center mt-4">
                  <Col lg="3">
                    <label className="form-control-label">Phone Number: </label>
                  </Col>
                  <Col lg="9">
                    <Input
                      type="text"
                      mask="(999) 999-9999"
                      tag={InputMask}
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                  </Col>
                </Row>

                <Row className="d-flex aligm-items-center mt-4">
                  <Col lg="3">
                    <label className="form-control-label">Permission Group:</label>
                  </Col>
                  <Col lg="9">
                    <Input type="select" onChange={(e) => setGroupId(e.target.value)} value={groupId} required>
                      <option value=""></option>
                      {groupsList?.map((row: any, index: number) => {
                        return (
                          <option value={row.id} key={`permission-group-${index}`}>
                            {row.name}
                          </option>
                        );
                      })}
                    </Input>
                  </Col>
                </Row>

                <Row className="d-flex aligm-items-center mt-4">
                  <Col lg="3">
                    <label className="form-control-label">Classification:</label>
                  </Col>
                  <Col lg="9">
                    <div className="d-flex flex-column flex-wrap">
                      <div className="form-check form-check-inline mr-3">
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="radio1"
                          id="classification-ft"
                          value={'FT'}
                          checked={classification === 'FT'}
                          onChange={(e) => setClassification(e.target.value)}
                          required
                        />
                        <Label className="form-check-label mr-2" htmlFor="classification-ft">
                          Full Time
                        </Label>
                      </div>
                      <div className="form-check form-check-inline mr-3">
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="radio1"
                          id="classification-pt"
                          value={'PT'}
                          checked={classification === 'PT'}
                          onChange={(e) => setClassification(e.target.value)}
                          required
                        />
                        <Label className="form-check-label mr-2" htmlFor="classification-pt">
                          Part Time
                        </Label>
                      </div>

                      <div className="form-check form-check-inline mr-3">
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="radio1"
                          id="classification-sub"
                          value={'Sub'}
                          checked={classification === 'Sub'}
                          onChange={(e) => setClassification(e.target.value)}
                          required
                        />
                        <Label className="form-check-label mr-2" htmlFor="classification-sub">
                          Sub
                        </Label>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className="d-flex aligm-items-center mt-4">
                  <Col lg="3">
                    <label className="form-control-label">Payment Frequency:</label>
                  </Col>
                  <Col lg="9">
                    <Input
                      type="select"
                      value={paymentFrequency}
                      onChange={(e) => setPaymentFrequency(e.target.value)}
                      required
                    >
                      <option value=""></option>
                      <option value={'hourly'}>Hourly</option>
                      {(classification === 'FT' || classification === '') && (
                        <option value={'annually'}>Annually</option>
                      )}
                    </Input>
                  </Col>
                </Row>

                <Row className="d-flex aligm-items-center mt-4">
                  <Col lg="3">
                    <label className="form-control-label">Amount:</label>
                  </Col>
                  <Col lg="9">
                    <Input
                      type="text"
                      thousandsGroupStyle="thousand"
                      prefix="$"
                      decimalSeparator="."
                      displayType="input"
                      thousandSeparator={true}
                      allowNegative={false}
                      decimalScale={2}
                      tag={NumberFormat}
                      value={amount}
                      onValueChange={(e: any) => setAmount(e.floatValue)}
                    />
                  </Col>
                </Row>

                <Row className="d-flex aligm-items-center mt-4">
                  <Col lg="3">
                    <label className="form-control-label">PTO Policy:</label>
                  </Col>
                  <Col lg="9">
                    <Input type="select" value={policy} onChange={(e) => setPolicy(e.target.value)} required>
                      <option value={''}></option>
                      {ptoPolices.map((value) => (
                        <option key={value.id} value={value.id}>
                          {value.name}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>

                <hr />

                <div className="d-flex justify-content-end mt-4">
                  <div>
                    <Button className="btn-secondary" onClick={toogleNewEmployeeForm}>
                      Cancel
                    </Button>
                    <Button color="warning" type="submit" disabled={email === 'john@arb.systems'}>
                      Continue
                    </Button>
                  </div>
                </div>
              </Form>
            )}

            {newEmployeeFormStep === 1 && (
              <Form onSubmit={handleSubmitForm}>
                <Row className="d-flex aligm-items-center">
                  <Col lg="3">
                    <label className="form-control-label">Job Title:</label>
                  </Col>
                  <Col lg="9">
                    <Input type="select" onChange={(e) => setTitleId(e.target.value)} value={titleId} required>
                      <option value=""></option>
                      {titleList?.map((filter: any, index: number) => {
                        return (
                          <option value={filter.id} key={`title-${index}`}>
                            {filter.name}
                          </option>
                        );
                      })}
                    </Input>
                  </Col>
                </Row>

                <Row className="d-flex aligm-items-center mt-4">
                  <Col lg="3">
                    <label className="form-control-label">Work Location:</label>
                  </Col>
                  <Col lg="9">
                    <div className="d-flex flex-column flex-wrap">
                      <div className="form-check form-check-inline mr-3">
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="radio1"
                          id="work-location"
                          checked={true}
                        />
                        <Label className="form-check-label mr-2" htmlFor="work-location">
                          Company Location
                        </Label>
                      </div>
                    </div>
                    <Input className="mt-1" type="text" value={currentSchool.label} disabled />
                  </Col>
                </Row>

                <Row className="d-flex aligm-items-center mt-4">
                  <Col lg="3">
                    <label className="form-control-label">Hire Date:</label>
                  </Col>
                  <Col lg="9">
                    <FormGroup className="custom-datepicker">
                      <InputGroup className="input-group-alternative">
                        <ReactDatetime
                          inputProps={{
                            placeholder: 'Start Date',
                          }}
                          onChange={(newValue) => {
                            setHireDate(moment(newValue).format('MM/DD/yyyy'));
                          }}
                          timeFormat={false}
                          value={`${hireDate}`}
                          dateFormat={'MM/DD/yyyy'}
                          closeOnSelect={true}
                        />
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-calendar-grid-58" />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="d-flex aligm-items-center mt-4">
                  <Col lg="3">
                    <label className="form-control-label">Department:</label>
                  </Col>
                  <Col lg="9">
                    <Input
                      type="select"
                      onChange={(e) => setDepartmentId(e.target.value)}
                      value={departmentId}
                      required
                    >
                      <option value=""></option>
                      {departmentList?.map((filter: any, index: number) => {
                        return (
                          <option value={filter.id} key={`department-${index}`}>
                            {filter.name}
                          </option>
                        );
                      })}
                    </Input>
                  </Col>
                </Row>

                <Row className="d-flex aligm-items-center mt-4">
                  <Col lg="3">
                    <label className="form-control-label">Supervisor:</label>
                  </Col>
                  <Col lg="9">
                    <Input
                      type="select"
                      onChange={(e) => setSupervisorId(e.target.value)}
                      value={supervisorId}
                      required
                    >
                      <option value=""></option>
                      {employees
                        ?.filter((e: any) => e.status !== 'TERMINATED')
                        .map((filter: any, index: number) => {
                          return (
                            <option value={filter.userId} key={`user-role-${index}`}>
                              {`${filter?.firstName} ${filter?.lastName}`}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>
                </Row>

                <Row className="d-flex aligm-items-center mt-4">
                  <Col lg="3">
                    <label className="form-control-label">Offer Letter:</label>
                  </Col>
                  <Col lg="9">
                    <div className="d-flex flex-column flex-wrap">
                      <div className="form-check form-check-inline mr-3">
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="offer-letter-radio"
                          id="offer-letter-dont-include"
                          checked={isIncludeOfferLetter === false}
                          onChange={() => {
                            setIsIncludeOfferLetter(false);
                            setOfferLetter('');
                          }}
                          required
                        />
                        <Label className="form-check-label mr-2" htmlFor="offer-letter-dont-include">
                          Don't include an offer letter
                        </Label>
                      </div>
                      <div className="form-check form-check-inline mr-3">
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="offer-letter-radio"
                          id="offer-letter-use-template"
                          checked={isIncludeOfferLetter === true}
                          onChange={() => setIsIncludeOfferLetter(true)}
                          required
                        />
                        <Label className="form-check-label mr-2" htmlFor="offer-letter-use-template">
                          Use an offer letter template
                        </Label>
                      </div>
                    </div>
                    {isIncludeOfferLetter && (
                      <Input
                        className="mt-1"
                        type="select"
                        value={offerLetter}
                        required
                        onChange={(e) => setOfferLetter(e.target.value)}
                      >
                        <option value=""></option>
                        <option value="Management">Management Offer Letter</option>
                        <option value="Staff">Staff Offer Letter</option>

                        {}
                      </Input>
                    )}
                  </Col>
                </Row>

                <hr />

                <div className="d-flex justify-content-end mt-4">
                  <div>
                    <Button className="btn-secondary" onClick={() => setNewEmployeeFormStep(0)}>
                      Back
                    </Button>
                    <Button color="warning" type="submit">
                      Continue
                    </Button>
                  </div>
                </div>
              </Form>
            )}

            {newEmployeeFormStep === 2 && (
              <EmploymentDocumentsForm
                hireAgreementsList={hireAgreementsList}
                hiringAgreements={hiringAgreements}
                taxDocs={taxDocs}
                employmentEligibilityDocs={employmentEligibilityDocs}
                changeEmploymentEligibility={changeEmploymentEligibility}
                handleSubmitForm={handleSubmitForm}
                setHiringAgreements={setHiringAgreements}
                setNewEmployeeFormStep={setNewEmployeeFormStep}
                selectAllDocs={selectAllDocs}
                setI9Option={setI9Option}
                i9Option={i9Option}
                changeOtherDocs={changeOtherDocs}
                otherDocs={otherDocs}
              />
            )}

            {newEmployeeFormStep === 3 && (
              <Form onSubmit={handleSubmitForm}>
                <Row className="d-flex aligm-items-center mt-4">
                  <Col lg="3">
                    <label className="form-control-label">Add to Payroll:</label>
                  </Col>
                  <Col lg="9">
                    <div className="d-flex flex-column flex-wrap">
                      <div className="form-check form-check-inline mr-3">
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="payroll-radio"
                          id="payroll-yes"
                          checked={isAddToPayroll === true}
                          onChange={() => setIsAddToPayroll(true)}
                          required
                        />
                        <Label className="form-check-label mr-2" htmlFor="payroll-yes">
                          Yes
                        </Label>
                      </div>

                      <div className="form-check form-check-inline mr-3">
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="payroll-radio"
                          id="payroll-no"
                          checked={isAddToPayroll === false}
                          onChange={() => setIsAddToPayroll(false)}
                          required
                        />
                        <Label className="form-check-label mr-2" htmlFor="payroll-no">
                          No
                        </Label>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className="d-flex aligm-items-center mt-4">
                  <Col lg="3">
                    <label className="form-control-label">Collect Tax Information:</label>
                  </Col>
                  <Col lg="9">
                    <div className="d-flex flex-column flex-wrap">
                      <div className="form-check form-check-inline mr-3">
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="collect-tax-information-radio"
                          id="collect-tax-information-yes"
                          checked={isCollectTaxInformation === true}
                          onChange={() => setIsCollectTaxInformation(true)}
                          required
                        />
                        <Label className="form-check-label mr-2" htmlFor="collect-tax-information-yes">
                          Yes
                        </Label>
                      </div>

                      <div className="form-check form-check-inline mr-3">
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="collect-tax-information-radio"
                          id="collect-tax-information-no"
                          checked={isCollectTaxInformation === false}
                          onChange={() => setIsCollectTaxInformation(false)}
                          required
                        />
                        <Label className="form-check-label mr-2" htmlFor="collect-tax-information-no">
                          No
                        </Label>
                      </div>
                    </div>
                  </Col>
                </Row>

                <hr />

                <div className="d-flex justify-content-end mt-4">
                  <div>
                    <Button className="btn-secondary" onClick={() => setNewEmployeeFormStep(2)}>
                      Back
                    </Button>
                    <Button color="warning" type="submit">
                      Continue
                    </Button>
                  </div>
                </div>
              </Form>
            )}

            {newEmployeeFormStep === 4 && (
              <Form onSubmit={handleSubmitForm}>
                <Row className="d-flex aligm-items-center mt-4">
                  <h2>
                    Review {firstName} {lastName} offer letter.
                  </h2>
                </Row>

                <hr />
                {offerLetter === 'Management' ? (
                  <ManagementOfferLetter
                    school={school}
                    user={user}
                    email={email}
                    phone={phone}
                    firstName={firstName}
                    lastName={lastName}
                    hireDate={hireDate}
                    classification={classification}
                    currentSchool={currentSchool}
                    paymentFrequency={paymentFrequency || ''}
                    amount={amount}
                    signature={signature}
                    policy={policy}
                    ptoPolices={ptoPolices}
                    handleFindTitleName={handleFindTitleName}
                    handleFindSupervisorName={handleFindSupervisorName}
                  />
                ) : (
                  <></>
                )}
                {offerLetter === 'Staff' ? (
                  <StaffOfferLetter
                    school={school}
                    user={user}
                    email={email}
                    phone={phone}
                    firstName={firstName}
                    lastName={lastName}
                    hireDate={hireDate}
                    classification={classification}
                    paymentFrequency={paymentFrequency || ''}
                    amount={amount}
                    signature={signature}
                    handleFindTitleName={handleFindTitleName}
                    handleFindSupervisorName={handleFindSupervisorName}
                  />
                ) : (
                  <></>
                )}
                {!offerLetter ? <span>Offer Letter not included.</span> : <></>}
                <Row>
                  <Col lg="12" className="mt-4">
                    <Label>
                      <strong>Signature:</strong>
                    </Label>
                    <div className="d-flex flex-wrap align-items-center">
                      <div className="form-check form-check-inline mr-3">
                        <Input
                          className="form-check-input"
                          type="checkbox"
                          id="to-sign"
                          checked={isSign}
                          onChange={() => {
                            if (isSign) {
                              setSignature('');
                            }
                            setIsSign(!isSign);
                          }}
                        />
                        <Label className="form-check-label mr-2" htmlFor="to-sign">
                          I hereby acknowledge and agree that my electronic signature on this document is the legal
                          equivalent of my handwritten signature.
                        </Label>
                      </div>
                    </div>
                  </Col>

                  {isSign && (
                    <Col lg="12" className="mt-4">
                      <FormGroup>
                        <Label htmlFor="name-for-signature">
                          <strong>Enter your name for signature:</strong>
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          id="name-for-signature"
                          style={{ fontFamily: 'Dancing Script' }}
                          value={signature}
                          onChange={(e) => {
                            setSignature(e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  )}
                </Row>

                <div className="d-flex justify-content-end mt-4">
                  <div>
                    <Button className="btn-secondary" onClick={() => setNewEmployeeFormStep(3)}>
                      Back
                    </Button>
                    <Button color="warning" type="submit">
                      Send
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </div>
        </div>
      </Modal>
      <TerminatedEmployeeForm
        isTerminatedEmployeeFormOpen={isTerminatedEmployeeFormOpen}
        toogleTerminatedEmployeeForm={toogleTerminatedEmployeeForm}
        handleSubmitTerminatedUser={handleSubmitTerminatedUser}
        terminatedEmployeeStep={terminatedEmployeeStep}
        terminationUser={terminationUser}
        terminationDate={terminationDate}
        setTerminationDate={setTerminationDate}
        terminationCategory={terminationCategory}
        setTerminationCategory={setTerminationCategory}
        willBeRehired={willBeRehired}
        setWillBeRehired={setWillBeRehired}
        terminationReason={terminationReason}
        setTerminationReason={setTerminationReason}
        terminationNotes={terminationNotes}
        setTerminationNotes={setTerminationNotes}
        terminationUserEmail={terminationUserEmail}
        setTerminationUserEmail={setTerminationUserEmail}
        terminationNameToConfirm={terminationNameToConfirm}
        setTerminationNameToConfirm={setTerminationNameToConfirm}
        setTerminatedEmployeeStep={setTerminatedEmployeeStep}
      />
      <RevokeOfferForm
        isRevokeHireOpen={isRevokeHireOpen}
        toogleRevokeHire={toogleRevokeHire}
        revokeHireUser={revokeHireUser}
        revokeNameToConfirm={revokeNameToConfirm}
        setRevokeNameToConfirm={setRevokeNameToConfirm}
        handleSubmit={(e: any) => {
          e?.preventDefault();
          revokeOffer();
        }}
      />
      <RevokeTerminationForm
        isOpen={isRevokeTerminationOpen}
        toogle={toogleRevokeTermination}
        revokeUser={revokeTerminationUser}
      />
      <RehireForm
        isOpen={isRehireOpen}
        toogle={toogleRehire}
        user={rehireUser}
        handleSubmit={(e: any) => {
          e?.preventDefault();
          setConfirmRehire(true);
          toogleRehire();
        }}
      />
      <Container className="mt--8" fluid>
        <Card className="bg-secondary shadow">
          <CardHeader>
            <h2 className="mb-3">Directory List</h2>
            <Row>
              <Col md="8" xs="12">
                <Input
                  type="text"
                  placeholder="Search by Name"
                  style={{ width: '100%' }}
                  value={search}
                  onChange={(event: any) => setSearch(event.target.value)}
                />
              </Col>
              {!isMobile ? (
                <Col md="4" xs="4">
                  <Button className="btn" color="primary" onClick={toogleNewEmployeeForm}>
                    Create New
                  </Button>
                </Col>
              ) : (
                <></>
              )}
            </Row>
          </CardHeader>

          <CardBody style={isMobile ? { paddingLeft: 5, paddingRight: 5 } : {}}>
            <Row>
              <Col xs="12">
                <DataTable columns={columns} data={data} totalRows={pagedMeta.total} loading={loading} />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default DirectoryList;
