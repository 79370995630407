import { FormGroup, Label, Input, Row, Col } from 'reactstrap';

const PreEmploymentReferencesForm = ({ handleChange, formData }: { handleChange: (e: any) => void; formData: any }) => {
  return (
    <>
      <FormGroup>
        <Label for="references">
          <strong>References</strong>: List the names, addresses, and phone numbers of people we may contact as
          references:
        </Label>
        <Input
          type="textarea"
          name="references"
          id="references"
          value={formData.references}
          onChange={handleChange}
          maxLength={130}
        />
      </FormGroup>

      <FormGroup>
        <Label>
          <strong>Physical Record</strong>: Are there any physical or personal limitations on the type of work you can
          do with children at school or the amount of time you can spend at work?
        </Label>
        <div>
          <FormGroup check inline>
            <Label check>
              <Input
                type="radio"
                name="physicalLimitations"
                value="no"
                checked={formData.physicalLimitations === 'no'}
                onChange={handleChange}
              />
              No
            </Label>
          </FormGroup>
          <FormGroup check inline>
            <Label check>
              <Input
                type="radio"
                name="physicalLimitations"
                value="yes"
                checked={formData.physicalLimitations === 'yes'}
                onChange={handleChange}
              />
              Yes
            </Label>
          </FormGroup>
        </div>
        {formData.physicalLimitations === 'yes' && (
          <FormGroup>
            <Label for="physicalLimitationsExplanation">Explain if applicable:</Label>
            <Input
              type="textarea"
              name="physicalLimitationsExplanation"
              id="physicalLimitationsExplanation"
              value={formData.physicalLimitationsExplanation}
              onChange={handleChange}
              maxLength={130}
            />
          </FormGroup>
        )}
      </FormGroup>

      <p>
        I understand that this an application for employment and that no employment contract is being offered. I
        authorize the prospective employer to inquire as to my record of any or all persons and of my former employers.
        In the event of my employment with Amazing Explorers Academy, I agree to comply with the rules and regulations
        governing my employment. In the event I should terminate my employment, I agree to file my resignation two weeks
        prior to the date effective.
      </p>
      <p>
        I understand that if I am employed, such an employment is for an indefinite period, and that Amazing Explorers
        Academy can change wages, benefits, and conditions at any time. I also understand that I may voluntarily leave
        or be terminated at any time and for any reason.
      </p>
      <p>
        I understand that if I am employed, the first ninety (90) days of my employment is a probationary period, and if
        my services have not proved satisfactory, my employment may be terminated without prejudice.
      </p>

      <Row form>
        <Col md={6}>
          <FormGroup>
            <Label for="signature">Applicant's Signature:</Label>
            <Input type="text" name="signature" id="signature" value={formData.signature} onChange={handleChange} />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="date">Date:</Label>
            <Input type="date" name="date" id="date" value={formData.date} onChange={handleChange} />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default PreEmploymentReferencesForm;
