import { Row, Col, FormGroup, Label, Input } from 'reactstrap';

const PreEmploymentPersonalDataForm = ({
  handleChange,
  formData,
}: {
  handleChange: (e: any) => void;
  formData: any;
}) => {
  return (
    <>
      <Row form>
        <Col md={4}>
          <FormGroup>
            <Label for="legalFirstName">Legal First Name</Label>
            <Input
              type="text"
              name="legalFirstName"
              id="legalFirstName"
              value={formData.legalFirstName}
              onChange={handleChange}
              maxLength={40}
              required
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="legalLastName">Legal Last Name</Label>
            <Input
              type="text"
              name="legalLastName"
              id="legalLastName"
              value={formData.legalLastName}
              onChange={handleChange}
              maxLength={40}
              required
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="maidenName">Maiden Name (if applicable)</Label>
            <Input
              type="text"
              name="maidenName"
              id="maidenName"
              value={formData.maidenName}
              onChange={handleChange}
              maxLength={40}
            />
          </FormGroup>
        </Col>
      </Row>

      <FormGroup>
        <Label for="address">Address</Label>
        <Input
          type="text"
          name="address"
          id="address"
          value={formData.address}
          onChange={handleChange}
          required
          maxLength={130}
        />
      </FormGroup>

      <Row form>
        <Col md={4}>
          <FormGroup>
            <Label for="county">Country</Label>
            <Input
              type="text"
              name="county"
              id="county"
              value={formData.county}
              onChange={handleChange}
              required
              maxLength={40}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="city">City</Label>
            <Input
              type="text"
              name="city"
              id="city"
              value={formData.city}
              onChange={handleChange}
              required
              maxLength={40}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="state">State</Label>
            <Input
              type="text"
              name="state"
              id="state"
              value={formData.state}
              onChange={handleChange}
              required
              maxLength={40}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="zipCode">Zip Code</Label>
            <Input
              type="text"
              name="zipCode"
              id="zipCode"
              value={formData.zipCode}
              onChange={handleChange}
              required
              maxLength={40}
            />
          </FormGroup>
        </Col>
      </Row>

      <FormGroup>
        <Label for="otherStatesLived">Any other state lived in within past five (5) years (list all):</Label>
        <Input
          type="text"
          name="otherStatesLived"
          id="otherStatesLived"
          value={formData.otherStatesLived}
          onChange={handleChange}
          maxLength={45}
        />
      </FormGroup>

      <Row form>
        <Col md={6}>
          <FormGroup>
            <Label for="phoneNumber">Phone Number</Label>
            <Input
              type="text"
              name="phoneNumber"
              id="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input type="email" name="email" id="email" value={formData.email} onChange={handleChange} />
          </FormGroup>
        </Col>
      </Row>

      <Row form>
        <Col md={6}>
          <FormGroup>
            <Label for="dob">D.O.B:</Label>
            <Input type="text" name="dob" id="dob" value={formData.dob} onChange={handleChange} />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="ssn">Social Security Number:</Label>
            <Input type="text" name="ssn" id="ssn" value={formData.ssn} onChange={handleChange} />
          </FormGroup>
        </Col>
      </Row>

      <Row form>
        <Col md={6}>
          <FormGroup>
            <Label for="shirtSize">Shirt Size</Label>
            <Input type="text" name="shirtSize" id="shirtSize" value={formData.shirtSize} onChange={handleChange} />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="allergies">Any Allergies:</Label>
            <Input
              type="text"
              name="allergies"
              id="allergies"
              value={formData.allergies}
              onChange={handleChange}
              maxLength={45}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default PreEmploymentPersonalDataForm;
