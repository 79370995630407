import { FormGroup, Label, Input, CustomInput } from 'reactstrap';

const PreEmploymentQuestionsForm = ({ handleChange, formData }: { handleChange: (e: any) => void; formData: any }) => {
  return (
    <>
      <h4 className="mt-3">Please answer the following questions:</h4>

      <FormGroup>
        <Label>
          1. Have you ever held a childcare license with any state licensing agency or been registered to provide
          childcare in your home?
        </Label>
        <div>
          <CustomInput
            type="checkbox"
            id="licenseHeldYes"
            label="Yes"
            name="licenseHeld"
            checked={formData.licenseHeld === true}
            onChange={handleChange}
            inline
          />
          <CustomInput
            type="checkbox"
            id="licenseHeldNo"
            label="No"
            name="licenseHeld"
            checked={formData.licenseHeld === false}
            onChange={handleChange}
            inline
          />
        </div>
      </FormGroup>

      <FormGroup>
        <Label>
          2. While employed in a childcare program, have you ever been the subject of disciplinary action, or been the
          part resp care facility receiving an administrative fine or other disciplinary action?
        </Label>
        <div>
          <CustomInput
            type="checkbox"
            id="disciplinaryActionYes"
            label="Yes"
            name="disciplinaryAction"
            checked={formData.disciplinaryAction === true}
            onChange={handleChange}
            inline
          />
          <CustomInput
            type="checkbox"
            id="disciplinaryActionNo"
            label="No"
            name="disciplinaryAction"
            checked={formData.disciplinaryAction === false}
            onChange={handleChange}
            inline
          />
        </div>
      </FormGroup>

      <FormGroup>
        <Label>3. Have you ever had an abuse or neglect or child maltreatment substantiation?</Label>

        <div>
          <CustomInput
            type="checkbox"
            id="abuseNeglectYes"
            label="Yes"
            name="abuseNeglect"
            checked={formData.abuseNeglect === true}
            onChange={handleChange}
            inline
          />
          <CustomInput
            type="checkbox"
            id="abuseNeglectNo"
            label="No"
            name="abuseNeglect"
            checked={formData.abuseNeglect === false}
            onChange={handleChange}
            inline
          />
        </div>
        {formData.abuseNeglect === true && (
          <FormGroup>
            <Label for="abuseNeglectExplanation">If yes, list county/state and give date and full explanation.</Label>
            <Input
              type="textarea"
              name="abuseNeglectExplanation"
              id="abuseNeglectExplanation"
              value={formData.abuseNeglectExplanation}
              onChange={handleChange}
            />
          </FormGroup>
        )}
      </FormGroup>

      <FormGroup>
        <Label>4. Are you at least 18 years of age?</Label>
        <div>
          <CustomInput
            type="checkbox"
            id="atLeast18Yes"
            label="Yes"
            name="atLeast18"
            checked={formData.atLeast18 === true}
            onChange={handleChange}
            inline
          />
          <CustomInput
            type="checkbox"
            id="atLeast18No"
            label="No"
            name="atLeast18"
            checked={formData.atLeast18 === false}
            onChange={handleChange}
            inline
          />
        </div>
      </FormGroup>

      <FormGroup className="ml-3">
        <Label>Have you ever been convicted of a crime or felony other than a minor traffic violation?</Label>
        <div>
          <CustomInput
            type="checkbox"
            id="convictedOfCrimeYes"
            label="Yes"
            name="convictedOfCrime"
            checked={formData.convictedOfCrime === true}
            onChange={handleChange}
            inline
          />
          <CustomInput
            type="checkbox"
            id="convictedOfCrimeNo"
            label="No"
            name="convictedOfCrime"
            checked={formData.convictedOfCrime === false}
            onChange={handleChange}
            inline
          />
        </div>
        {formData.convictedOfCrime === true && (
          <FormGroup>
            <Label for="crimeExplanation">If yes, give date and explain fully.</Label>
            <Input
              type="textarea"
              name="crimeExplanation"
              id="crimeExplanation"
              value={formData.crimeExplanation}
              onChange={handleChange}
            />
          </FormGroup>
        )}
      </FormGroup>

      <FormGroup>
        <Label>5. Do you object to being fingerprinted?</Label>
        <div>
          <CustomInput
            type="checkbox"
            id="objectToFingerprintingYes"
            label="Yes"
            name="objectToFingerprinting"
            checked={formData.objectToFingerprinting === true}
            onChange={handleChange}
            inline
          />
          <CustomInput
            type="checkbox"
            id="objectToFingerprintingNo"
            label="No"
            name="objectToFingerprinting"
            checked={formData.objectToFingerprinting === false}
            onChange={handleChange}
            inline
          />
        </div>
      </FormGroup>

      <FormGroup>
        <Label>6. Are you legally able to work in the United States?</Label>
        <div>
          <CustomInput
            type="checkbox"
            id="legallyAbleToWorkYes"
            label="Yes"
            name="legallyAbleToWork"
            checked={formData.legallyAbleToWork === true}
            onChange={handleChange}
            inline
          />
          <CustomInput
            type="checkbox"
            id="legallyAbleToWorkNo"
            label="No"
            name="legallyAbleToWork"
            checked={formData.legallyAbleToWork === false}
            onChange={handleChange}
            inline
          />
        </div>
      </FormGroup>

      <FormGroup>
        <Label>
          7. Do you have any 3rd (third) party agreements that preclude you from being employed by Amazing Explorers
          Acad disclosure and/or a non-compete agreement).
        </Label>
        <div>
          <CustomInput
            type="checkbox"
            id="thirdPartyAgreementYes"
            label="Yes"
            name="thirdPartyAgreement"
            checked={formData.thirdPartyAgreement === true}
            onChange={handleChange}
            inline
          />
          <CustomInput
            type="checkbox"
            id="thirdPartyAgreementNo"
            label="No"
            name="thirdPartyAgreement"
            checked={formData.thirdPartyAgreement === false}
            onChange={handleChange}
            inline
          />
        </div>
      </FormGroup>
    </>
  );
};

export default PreEmploymentQuestionsForm;
