/* eslint-disable max-lines */
import { PDFCheckBox, PDFDocument, PDFDropdown, PDFTextField, rgb, StandardFonts } from 'pdf-lib';
import moment from 'moment';

interface FormData {
  legalFirstName: string;
  legalLastName: string;
  maidenName: string;
  address: string;
  county: string;
  city: string;
  state: string;
  zipCode: string;
  otherStatesLived: string;
  phoneNumber: string;
  email: string;
  dob: string;
  ssn: string;
  shirtSize: string;
  allergies: string;
  emergencyName: string;
  emergencyRelationship: string;
  emergencyAddress: string;
  emergencyPhoneH: string;
  emergencyPhoneC: string;
  emergencyPhoneOther: string;
  licenseHeld: boolean | null;
  disciplinaryAction: boolean | null;
  abuseNeglect: boolean | null;
  abuseNeglectExplanation: string;
  atLeast18: boolean | null;
  convictedOfCrime: boolean | null;
  crimeExplanation: string;
  objectToFingerprinting: boolean | null;
  legallyAbleToWork: boolean | null;
  thirdPartyAgreement: boolean | null;
  highestGradeCompleted: string;
  gedCollegeCompleted: string;
  highSchoolNameLocation: string;
  highSchoolDatesAttended: string;
  highSchoolCourseOfStudy: string;
  highSchoolDegreeDiploma: string;
  highSchoolNameLocation2: string;
  highSchoolDatesAttended2: string;
  highSchoolCourseOfStudy2: string;
  highSchoolDegreeDiploma2: string;
  collegeUniversityNameLocation: string;
  collegeUniversityDatesAttended: string;
  collegeUniversityCourseOfStudy: string;
  collegeUniversityDegreeDiploma: string;
  collegeUniversityNameLocation2: string;
  collegeUniversityDatesAttended2: string;
  collegeUniversityCourseOfStudy2: string;
  collegeUniversityDegreeDiploma2: string;
  graduateProfessionalNameLocation: string;
  graduateProfessionalDatesAttended: string;
  graduateProfessionalCourseOfStudy: string;
  graduateProfessionalDegreeDiploma: string;
  graduateProfessionalNameLocation2: string;
  graduateProfessionalDatesAttended2: string;
  graduateProfessionalCourseOfStudy2: string;
  graduateProfessionalDegreeDiploma2: string;
  stateCredentialNameLocation: string;
  stateCredentialDatesAttended: string;
  stateCredentialCourseOfStudy: string;
  stateCredentialDegreeDiploma: string;
  childcareTraining: string;
  references: string;
  physicalLimitations: string;
  physicalLimitationsExplanation: string;
  signature: string;
  date: string;
}

interface EmploymentHistoryEntry {
  placeOfEmployment: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  datesOfEmploymentFrom: string;
  datesOfEmploymentUntil: string;
  positionHeld: string;
  supervisor: string;
  reasonForLeaving: string;
  jobDescription: string;
}

interface EmploymentHistory {
  applicantName: string;
  positionAppliedFor: string;
  history: EmploymentHistoryEntry[];
}

const readOnlyFields = (doc: any) => {
  try {
    if (!doc?.getForm) return;
    const pdfForm = doc.getForm();
    if (!pdfForm?.getFields) return;
    const fieldNames = pdfForm.getFields();
    for (const f of fieldNames) {
      let type = '';
      if (f instanceof PDFTextField) {
        type = 'PDFTextField';
      }
      if (f instanceof PDFDropdown) {
        type = 'PDFDropdown';
      }
      if (f instanceof PDFCheckBox) {
        type = 'PDFCheckBox';
      }
      const fieldName = f.getName();
      if (type === 'PDFTextField' || f instanceof PDFTextField) {
        pdfForm.getTextField(fieldName).enableReadOnly();
      }
      if (type === 'PDFDropdown' || f instanceof PDFDropdown) {
        pdfForm.getDropdown(fieldName).enableReadOnly();
      }
      if (type === 'PDFCheckBox' || f instanceof PDFCheckBox) {
        pdfForm.getCheckBox(fieldName).enableReadOnly();
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export async function fillForm(
  fileUrl: string,
  formData: FormData,
  employmentHistory: EmploymentHistory,
): Promise<any> {
  try {
    const arrayBuffer = await fetch(fileUrl).then((res) => res.arrayBuffer());

    const pdfDoc: PDFDocument = await PDFDocument.load(arrayBuffer);
    readOnlyFields(pdfDoc);
    const pages = pdfDoc.getPages();

    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const fontSize = 10;
    const textColor = rgb(0, 0, 0);

    const drawTextField = (text: string, x: number, y: number, pageIndex: number = 0): void => {
      const page = pages[pageIndex];
      y = y - 75;
      if (page)
        page.drawText(String(text || ''), {
          x,
          y,
          font,
          size: fontSize,
          color: textColor,
        });
    };

    const drawCheckbox = (
      isChecked: boolean | null,
      x: number,
      y: number,
      pageIndex: number = 0,
      character: string = 'X',
    ): void => {
      const page = pages[pageIndex];
      if (isChecked) {
        page.drawText(character, {
          x: x + 2,
          y: y + 2,
          font,
          size: 8,
          color: textColor,
        });
      }
    };

    const drawRadioButton = (
      isChecked: boolean | null,
      x: number,
      y: number,
      pageIndex: number = 0,
      character: string = 'X',
    ): void => {
      const page = pages[pageIndex];
      if (isChecked) {
        page.drawText(character, {
          x,
          y,
          font,
          size: 12,
          color: textColor,
        });
      }
    };

    // Page 1
    drawTextField(formData.legalFirstName, 40, 732);
    drawTextField(formData.legalLastName, 210, 732);
    drawTextField(formData.maidenName, 400, 732);
    drawTextField(formData.address, 40, 687);
    drawTextField(formData.county, 40, 658);
    drawTextField(formData.city, 200, 658);
    drawTextField(formData.state, 320, 658);
    drawTextField(formData.zipCode, 420, 658);
    drawTextField(formData.otherStatesLived, 320, 612);
    drawTextField(formData.phoneNumber, 120, 582);
    drawTextField(formData.email, 320, 582);
    drawTextField(formData.dob, 80, 552);
    drawTextField(formData.ssn, 350, 552);
    drawTextField(formData.shirtSize, 120, 523);
    drawTextField(formData.allergies, 370, 523);
    drawTextField(formData.emergencyName, 80, 466);
    drawTextField(formData.emergencyRelationship, 378, 466);
    drawTextField(formData.emergencyAddress, 80, 437);
    drawTextField(formData.emergencyPhoneH, 126, 411);
    drawTextField(formData.emergencyPhoneC, 270, 411);
    drawTextField(formData.emergencyPhoneOther, 435, 411);

    drawCheckbox(formData.licenseHeld === true, 168, 248);
    drawCheckbox(formData.licenseHeld === false, 260, 248);

    drawRadioButton(formData.disciplinaryAction === true, 422, 203);
    drawRadioButton(formData.disciplinaryAction === false, 475, 203);

    drawRadioButton(formData.abuseNeglect === true, 455, 163);
    drawRadioButton(formData.abuseNeglect === false, 506, 163);

    drawTextField(formData.abuseNeglectExplanation, 300, 217);

    // Page 2
    drawCheckbox(formData.atLeast18 === true, 229, 670, 1);
    drawCheckbox(formData.atLeast18 === false, 285, 668, 1);

    drawRadioButton(formData.convictedOfCrime === true, 484, 644, 1);
    drawRadioButton(formData.convictedOfCrime === false, 550, 644, 1);

    drawTextField(formData.crimeExplanation, 229, 692, 1);

    drawRadioButton(formData.objectToFingerprinting === true, 255, 585, 1);
    drawRadioButton(formData.objectToFingerprinting === false, 370, 585, 1);

    drawRadioButton(formData.legallyAbleToWork === true, 325, 562, 1);
    drawRadioButton(formData.legallyAbleToWork === false, 400, 562, 1);

    drawRadioButton(formData.thirdPartyAgreement === true, 300, 520, 1);
    drawRadioButton(formData.thirdPartyAgreement === false, 360, 520, 1);

    const gradeOptions = [
      { value: '1', position: 193 },
      { value: '2', position: 203 },
      { value: '3', position: 213 },
      { value: '4', position: 222 },
      { value: '5', position: 230 },
      { value: '6', position: 240 },
      { value: '7', position: 250 },
      { value: '8', position: 258 },
      { value: '9', position: 268 },
      { value: '10', position: 278 },
      { value: '11', position: 293 },
      { value: '12', position: 309 },
    ];
    const getGradePosition = gradeOptions.find(
      (grade) => String(grade.value) === String(formData.highestGradeCompleted),
    );
    if (getGradePosition) drawTextField('X', getGradePosition.position, 532, 1);

    const collegeOptions = [
      { value: '1', position: 389 },
      { value: '2', position: 399 },
      { value: '3', position: 407 },
      { value: '4', position: 417 },
    ];
    const getCollegePosition = collegeOptions.find((col) => String(col.value) === String(formData.gedCollegeCompleted));
    if (getCollegePosition) drawTextField('X', getCollegePosition.position, 532, 1);

    drawTextField(formData.highSchoolNameLocation, 115, 472, 1);
    drawTextField(formData.highSchoolDatesAttended, 243, 472, 1);
    drawTextField(formData.highSchoolCourseOfStudy, 355, 472, 1);
    drawTextField(formData.highSchoolDegreeDiploma, 475, 472, 1);

    drawTextField(formData.highSchoolNameLocation2, 115, 445, 1);
    drawTextField(formData.highSchoolDatesAttended2, 243, 445, 1);
    drawTextField(formData.highSchoolCourseOfStudy2, 355, 445, 1);
    drawTextField(formData.highSchoolDegreeDiploma2, 475, 445, 1);

    drawTextField(formData.collegeUniversityNameLocation, 115, 418, 1);
    drawTextField(formData.collegeUniversityDatesAttended, 243, 418, 1);
    drawTextField(formData.collegeUniversityCourseOfStudy, 355, 418, 1);
    drawTextField(formData.collegeUniversityDegreeDiploma, 475, 418, 1);

    drawTextField(formData.collegeUniversityNameLocation2, 115, 394, 1);
    drawTextField(formData.collegeUniversityDatesAttended2, 243, 394, 1);
    drawTextField(formData.collegeUniversityCourseOfStudy2, 355, 394, 1);
    drawTextField(formData.collegeUniversityDegreeDiploma2, 475, 394, 1);

    drawTextField(formData.graduateProfessionalNameLocation, 115, 370, 1);
    drawTextField(formData.graduateProfessionalDatesAttended, 243, 370, 1);
    drawTextField(formData.graduateProfessionalCourseOfStudy, 355, 370, 1);
    drawTextField(formData.graduateProfessionalDegreeDiploma, 475, 370, 1);

    drawTextField(formData.graduateProfessionalNameLocation2, 115, 346, 1);
    drawTextField(formData.graduateProfessionalDatesAttended2, 243, 346, 1);
    drawTextField(formData.graduateProfessionalCourseOfStudy2, 355, 346, 1);
    drawTextField(formData.graduateProfessionalDegreeDiploma2, 475, 346, 1);

    drawTextField(formData.stateCredentialNameLocation, 115, 310, 1);
    drawTextField(formData.stateCredentialDatesAttended, 243, 310, 1);
    drawTextField(formData.stateCredentialCourseOfStudy, 355, 310, 1);
    drawTextField(formData.stateCredentialDegreeDiploma, 475, 310, 1);

    drawTextField(formData.childcareTraining, 115, 260, 1);

    // Page 3
    drawTextField(formData.references, 15, 726, 2);

    drawTextField('X', formData.physicalLimitations === 'yes' ? 210 : 165, 642, 2);
    drawTextField(formData.physicalLimitationsExplanation, 15, 628, 2);

    drawTextField(formData.signature, 160, 218, 2);
    const dateF = moment(formData.date);
    if (dateF.isValid()) drawTextField(dateF.format('MM/DD/YYYY'), 380, 218, 2);

    // Pages 4, 5, 6 - Employment History
    const historyEntries = employmentHistory.history;
    for (let i = 0; i < historyEntries.length; i++) {
      const historyEntry = historyEntries[i];

      if (i === 0) {
        drawTextField(employmentHistory.applicantName, 135, 767, 3);
        drawTextField(employmentHistory.positionAppliedFor, 380, 767, 3);
        drawTextField(historyEntry.placeOfEmployment, 165, 604, 3);
        drawTextField(historyEntry.phone, 433, 604, 3);
        drawTextField(historyEntry.address, 110, 578, 3);
        drawTextField(historyEntry.city, 355, 578, 3);
        drawTextField(historyEntry.state, 492, 578, 3);
        const datesOfEmploymentFrom = moment(historyEntry.datesOfEmploymentFrom);
        if (datesOfEmploymentFrom.isValid()) drawTextField(datesOfEmploymentFrom.format('MM/DD/YYYY'), 197, 563, 3);
        const datesOfEmploymentUntil = moment(historyEntry.datesOfEmploymentUntil);
        if (datesOfEmploymentUntil.isValid()) drawTextField(datesOfEmploymentUntil.format('MM/DD/YYYY'), 295, 563, 3);
        drawTextField(historyEntry.positionHeld, 428, 563, 3);
        drawTextField(historyEntry.supervisor, 120, 534, 3);
        drawTextField(historyEntry.reasonForLeaving, 63, 495, 3);
        drawTextField(historyEntry.jobDescription, 63, 438, 3);
      }

      if (i === 1) {
        drawTextField(historyEntry.placeOfEmployment, 165, 736, 4);
        drawTextField(historyEntry.phone, 433, 736, 4);
        drawTextField(historyEntry.address, 110, 710, 4);
        drawTextField(historyEntry.city, 355, 710, 4);
        drawTextField(historyEntry.state, 492, 710, 4);
        const datesOfEmploymentFrom = moment(historyEntry.datesOfEmploymentFrom);
        if (datesOfEmploymentFrom.isValid()) drawTextField(datesOfEmploymentFrom.format('MM/DD/YYYY'), 197, 695, 4);
        const datesOfEmploymentUntil = moment(historyEntry.datesOfEmploymentUntil);
        if (datesOfEmploymentUntil.isValid()) drawTextField(datesOfEmploymentUntil.format('MM/DD/YYYY'), 295, 695, 4);
        drawTextField(historyEntry.positionHeld, 428, 695, 4);
        drawTextField(historyEntry.supervisor, 120, 665, 4);
        drawTextField(historyEntry.reasonForLeaving, 63, 627, 4);
        drawTextField(historyEntry.jobDescription, 63, 579, 4);
      }

      if (i === 2) {
        drawTextField(historyEntry.placeOfEmployment, 165, 275, 4);
        drawTextField(historyEntry.phone, 433, 275, 4);
        drawTextField(historyEntry.address, 110, 249, 4);
        drawTextField(historyEntry.city, 355, 249, 4);
        drawTextField(historyEntry.state, 492, 249, 4);
        const datesOfEmploymentFrom = moment(historyEntry.datesOfEmploymentFrom);
        if (datesOfEmploymentFrom.isValid()) drawTextField(datesOfEmploymentFrom.format('MM/DD/YYYY'), 197, 234, 4);
        const datesOfEmploymentUntil = moment(historyEntry.datesOfEmploymentUntil);
        if (datesOfEmploymentUntil.isValid()) drawTextField(datesOfEmploymentUntil.format('MM/DD/YYYY'), 295, 234, 4);
        drawTextField(historyEntry.positionHeld, 428, 234, 4);
        drawTextField(historyEntry.supervisor, 120, 204, 4);
        drawTextField(historyEntry.reasonForLeaving, 63, 166, 4);
        drawTextField(historyEntry.jobDescription, 63, 122, 4);
      }

      if (i === 3) {
        drawTextField(historyEntry.placeOfEmployment, 169, 533, 5); //258
        drawTextField(historyEntry.phone, 433, 533, 5);
        drawTextField(historyEntry.address, 110, 512, 5);
        drawTextField(historyEntry.city, 360, 512, 5);
        drawTextField(historyEntry.state, 492, 512, 5);
        const datesOfEmploymentFrom = moment(historyEntry.datesOfEmploymentFrom);
        if (datesOfEmploymentFrom.isValid()) drawTextField(datesOfEmploymentFrom.format('MM/DD/YYYY'), 199, 493, 5);
        const datesOfEmploymentUntil = moment(historyEntry.datesOfEmploymentUntil);
        if (datesOfEmploymentUntil.isValid()) drawTextField(datesOfEmploymentUntil.format('MM/DD/YYYY'), 297, 493, 5);
        drawTextField(historyEntry.positionHeld, 437, 495, 5);
        drawTextField(historyEntry.supervisor, 123, 465, 5);
        drawTextField(historyEntry.reasonForLeaving, 67, 424, 5);
        drawTextField(historyEntry.jobDescription, 67, 380, 5);
      }
    }

    const modifiedPdfBytes: Uint8Array = await pdfDoc.save();
    return modifiedPdfBytes;
  } catch (error) {
    console.error('Error filling the PDF:', error);
  }
}
