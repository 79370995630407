import http from '../helpers/http';

const HireApplication = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    list: async () => {
      const result = await http.get(`/hire-application`, {
        headers,
      });
      return result.data;
    },
    show: async (id: string) => {
      const result = await http.get(`/hire-application/${id}`, {
        headers,
      });
      return result.data;
    },
    create: async (body: any) => {
      const result = await http.post(`/hire-application`, body, { headers });
      return result.data;
    },
    delete: async (id: string) => {
      const result = await http.delete(`/hire-application/${id}`, {
        headers,
      });
      return result.data;
    },
  };
};

export default HireApplication;
