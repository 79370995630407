import { Button, Col, Form, Input, Label, Row } from 'reactstrap';

interface EmploymentDocumentsFormProps {
  hireAgreementsList: any[];
  hiringAgreements: any[];
  taxDocs: any[];
  employmentEligibilityDocs: any[];
  i9Option: string;
  changeEmploymentEligibility: (e: any) => void;
  handleSubmitForm: (e: any) => void;
  setHiringAgreements: (e: any) => void;
  setNewEmployeeFormStep: (e: any) => void;
  selectAllDocs: () => void;
  setI9Option: (e: any) => void;
  changeOtherDocs: (e: any) => void;
  otherDocs: any[];
}

const EmploymentDocumentsForm = ({
  hireAgreementsList,
  hiringAgreements,
  taxDocs,
  employmentEligibilityDocs,
  changeEmploymentEligibility,
  handleSubmitForm,
  setHiringAgreements,
  setNewEmployeeFormStep,
  selectAllDocs,
  setI9Option,
  i9Option,
  changeOtherDocs,
  otherDocs,
}: EmploymentDocumentsFormProps) => {
  return (
    <Form onSubmit={handleSubmitForm}>
      <Row className="d-flex aligm-items-center">
        <Col lg="3">
          <label className="form-control-label"></label>
        </Col>
        <Col lg="9">
          <Button className="btn-secondary" type="button" onClick={() => selectAllDocs()} style={{ width: '150px' }}>
            Select All
          </Button>
        </Col>
      </Row>
      <Row className="d-flex aligm-items-center mt-4">
        <Col lg="3">
          <label className="form-control-label">Employment Eligibility:</label>
        </Col>
        <Col lg="9">
          <div className="d-flex flex-column flex-wrap">
            {['English', 'Spanish'].map((lang: string) => (
              <div className="form-check form-check-inline align-items-start" key={`i9-${lang}`}>
                <Input
                  className="form-check-input mt-2"
                  name="i9-option"
                  type="radio"
                  id={`i9-${lang}`}
                  value={`I9_${lang.toUpperCase()}`}
                  checked={`I9_${lang.toUpperCase()}` === i9Option || (i9Option === '' && lang === 'English')}
                  onChange={() => setI9Option(`I9_${lang.toUpperCase()}`)}
                />
                <Label className="form-check-label mr-2" htmlFor={`i9-${lang}`}>
                  I-9 ({lang})
                </Label>
              </div>
            ))}

            <div className="form-check form-check-inline mt-2 align-items-start">
              <Input
                className="form-check-input mt-2"
                type="checkbox"
                id="request-proof-eligibility-documents"
                checked={!!employmentEligibilityDocs.find((e) => e === 'TWO_DOCS_FOR_PROOF')}
                onChange={() => changeEmploymentEligibility('TWO_DOCS_FOR_PROOF')}
              />
              <Label className="form-check-label mr-2" htmlFor="request-proof-eligibility-documents">
                Employment Eligibility Documents
              </Label>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="d-flex aligm-items-center mt-4">
        <Col lg="3">
          <label className="form-control-label">Tax Documents:</label>
        </Col>
        <Col lg="9">
          <div className="d-flex flex-column flex-wrap">
            <div className="form-check form-check-inline mt-2 align-items-start">
              <Input
                className="form-check-input mt-2"
                type="checkbox"
                id="tax-documents-w4"
                checked={!!taxDocs.find((e) => e === 'W4')}
              />
              <Label className="form-check-label mr-2" htmlFor="tax-documents-w4">
                W-4
              </Label>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="d-flex aligm-items-center mt-4">
        <Col lg="3">
          <label className="form-control-label">Hiring Agreements:</label>
        </Col>
        <Col lg="9">
          <div className="d-flex flex-column flex-wrap">
            {hireAgreementsList.map((agreement) => (
              <div className="form-check form-check-inline mr-3" key={agreement.id}>
                <Input
                  className="form-check-input"
                  type="checkbox"
                  id={agreement.id}
                  value={agreement.id}
                  checked={hiringAgreements.includes(agreement.id)}
                  onChange={(e) => {
                    const isChecked = hiringAgreements.includes(e.target.value);
                    const newHiringAgreements = [...hiringAgreements];

                    if (isChecked) {
                      setHiringAgreements(newHiringAgreements.filter((value) => value !== e.target.value));
                    } else {
                      newHiringAgreements.push(e.target.value);
                      setHiringAgreements(newHiringAgreements);
                    }
                  }}
                />
                <Label className="form-check-label mr-2" htmlFor={agreement.id}>
                  {agreement.name}
                </Label>
              </div>
            ))}
          </div>
        </Col>
      </Row>

      <Row className="d-flex aligm-items-center mt-4">
        <Col lg="3">
          <label className="form-control-label">Company Handbook:</label>
        </Col>
        <Col lg="9">
          <div className="d-flex flex-column flex-wrap">
            <div className="form-check form-check-inline mr-3">
              <Input
                className="form-check-input"
                type="checkbox"
                id={'EMPLOYEE-HANDBOOK'}
                value={'EMPLOYEE-HANDBOOK'}
                checked={otherDocs.includes('EMPLOYEE-HANDBOOK')}
                onChange={() => changeOtherDocs('EMPLOYEE-HANDBOOK')}
              />
              <Label className="form-check-label mr-2" htmlFor={'EMPLOYEE-HANDBOOK'}>
                AEA Employee Handbook_REV_10-02-24
              </Label>
            </div>
          </div>
        </Col>
      </Row>

      <hr />

      <div className="d-flex justify-content-end mt-4">
        <div>
          <Button className="btn-secondary" onClick={() => setNewEmployeeFormStep(1)}>
            Back
          </Button>
          <Button color="warning" type="submit">
            Continue
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default EmploymentDocumentsForm;
