import { useState } from 'react';
import { toast } from 'react-toastify';
import { Container, Form, Button } from 'reactstrap';
import PreEmploymentPersonalDataForm from './PreEmploymentPersonalDataForm';
import PreEmploymentEmergencyForm from './PreEmploymentEmergencyForm';
import PreEmploymentQuestionsForm from './PreEmploymentQuestionsForm';
import PreEmploymentEducationForm from './PreEmploymentEducationForm';
import PreEmploymentReferencesForm from './PreEmploymentReferencesForm';
import PreEmploymentHistoryForm from './PreEmploymentHistoryForm';
import HireApplication from '../../../../services/HireApplication';

const PreEmploymentForm = ({
  currentUser,
  token,
  submited,
}: {
  currentUser: any;
  token: string;
  submited: () => void;
}) => {
  const [formData, setFormData] = useState({
    legalFirstName: '',
    legalLastName: '',
    maidenName: '',
    address: '',
    county: '',
    city: '',
    state: '',
    zipCode: '',
    otherStatesLived: '',
    phoneNumber: '',
    email: '',
    dob: '',
    ssn: '',
    shirtSize: '',
    allergies: '',
    emergencyName: '',
    emergencyRelationship: '',
    emergencyAddress: '',
    emergencyPhoneH: '',
    emergencyPhoneC: '',
    emergencyPhoneOther: '',
    licenseHeld: undefined,
    disciplinaryAction: undefined,
    abuseNeglect: undefined,
    abuseNeglectExplanation: '',
    atLeast18: undefined,
    convictedOfCrime: undefined,
    crimeExplanation: '',
    objectToFingerprinting: undefined,
    legallyAbleToWork: undefined,
    thirdPartyAgreement: undefined,
    highestGradeCompleted: '',
    gedCollegeCompleted: '',
    highSchoolNameLocation: '',
    highSchoolDatesAttended: '',
    highSchoolCourseOfStudy: '',
    highSchoolDegreeDiploma: '',
    highSchoolNameLocation2: '',
    highSchoolDatesAttended2: '',
    highSchoolCourseOfStudy2: '',
    highSchoolDegreeDiploma2: '',
    collegeUniversityNameLocation: '',
    collegeUniversityDatesAttended: '',
    collegeUniversityCourseOfStudy: '',
    collegeUniversityDegreeDiploma: '',
    collegeUniversityNameLocation2: '',
    collegeUniversityDatesAttended2: '',
    collegeUniversityCourseOfStudy2: '',
    collegeUniversityDegreeDiploma2: '',
    graduateProfessionalNameLocation: '',
    graduateProfessionalDatesAttended: '',
    graduateProfessionalCourseOfStudy: '',
    graduateProfessionalDegreeDiploma: '',
    graduateProfessionalNameLocation2: '',
    graduateProfessionalDatesAttended2: '',
    graduateProfessionalCourseOfStudy2: '',
    graduateProfessionalDegreeDiploma2: '',
    stateCredentialNameLocation: '',
    stateCredentialDatesAttended: '',
    stateCredentialCourseOfStudy: '',
    stateCredentialDegreeDiploma: '',
    childcareTraining: '',
    references: '',
    physicalLimitations: '',
    physicalLimitationsExplanation: '',
    signature: '',
    date: '',
  });

  const [employmentHistory, setEmploymentHistory] = useState({
    applicantName: '',
    positionAppliedFor: '',
    history: [
      {
        placeOfEmployment: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        datesOfEmploymentFrom: '',
        datesOfEmploymentUntil: '',
        positionHeld: '',
        supervisor: '',
        reasonForLeaving: '',
        jobDescription: '',
      },
      {
        placeOfEmployment: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        datesOfEmploymentFrom: '',
        datesOfEmploymentUntil: '',
        positionHeld: '',
        supervisor: '',
        reasonForLeaving: '',
        jobDescription: '',
      },
      {
        placeOfEmployment: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        datesOfEmploymentFrom: '',
        datesOfEmploymentUntil: '',
        positionHeld: '',
        supervisor: '',
        reasonForLeaving: '',
        jobDescription: '',
      },
      {
        placeOfEmployment: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        datesOfEmploymentFrom: '',
        datesOfEmploymentUntil: '',
        positionHeld: '',
        supervisor: '',
        reasonForLeaving: '',
        jobDescription: '',
      },
    ],
  });

  const handleInputChange = (index: any, event: any) => {
    const { name, value } = event.target;
    const updatedEmploymentHistory = JSON.parse(JSON.stringify(employmentHistory));
    if (name === 'applicantName' || name === 'positionAppliedFor') {
      updatedEmploymentHistory[name] = value;
      return setEmploymentHistory(updatedEmploymentHistory);
    }
    updatedEmploymentHistory.history[index][name] = value;
    setEmploymentHistory(updatedEmploymentHistory);
  };

  const handleChange = (e: any) => {
    const { name, type, id } = e.target;

    if (type === 'checkbox') {
      let value: boolean | null = null;

      if (id.endsWith('Yes')) {
        value = true;
      } else if (id.endsWith('No')) {
        value = false;
      }

      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      await HireApplication('').create({
        type: 'APPLICATION_FORM',
        userId: currentUser?.userId,
        userName: currentUser?.userName,
        userToken: token,
        json: JSON.stringify({ formData, employmentHistory }),
        signature: '',
      });
      toast.success('Application submitted successfully');
      submited();
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'Invalid user ID');
    }
  };

  return (
    <Container>
      <h2 className="text-primary">APPLICATION FOR PRE-EMPLOYMENT FORM</h2>
      <p>
        <b>Instructions:</b> All information on this pre-employment form must be truthful and correct.
        <br />
        <b>Falsification or misrepresentation on the application is cause for immediate dismissal.</b>
      </p>

      <Form onSubmit={handleSubmit}>
        <PreEmploymentPersonalDataForm formData={formData} handleChange={handleChange} />
        <PreEmploymentEmergencyForm formData={formData} handleChange={handleChange} />
        <PreEmploymentQuestionsForm formData={formData} handleChange={handleChange} />
        <PreEmploymentEducationForm formData={formData} handleChange={handleChange} />
        <PreEmploymentReferencesForm formData={formData} handleChange={handleChange} />
        <PreEmploymentHistoryForm employmentHistory={employmentHistory} handleInputChange={handleInputChange} />
        <Button color="primary">Submit</Button>
      </Form>
    </Container>
  );
};

export default PreEmploymentForm;
