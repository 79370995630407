import moment from 'moment';
import { FormGroup, Label, Input, Row, Col, Table, Button, Form, FormText } from 'reactstrap';

const BankAccountForm = ({
  setCurrentBankingAccount,
  setShowBankingModal,
  onRemoveBankAccount,
  onBankSaveFile,
  setBankName,
  setRoutingNumber,
  setAccountNumber,
  setConfirmAccountNumber,
  onChangeBankFile,
  banks,
  bankName,
  bankRoutingNumber,
  bankAccountNumber,
  bankConfirmAccountNumber,
}: {
  setCurrentBankingAccount: (e: any) => void;
  setShowBankingModal: (e: any) => void;
  onRemoveBankAccount: (e: any, i: any) => void;
  onBankSaveFile: (e: any) => void;
  setBankName: (e: any) => void;
  setRoutingNumber: (e: any) => void;
  setAccountNumber: (e: any) => void;
  setConfirmAccountNumber: (e: any) => void;
  onChangeBankFile: (e: any) => void;
  banks: any[];
  bankName: string;
  bankRoutingNumber: string;
  bankAccountNumber: string;
  bankConfirmAccountNumber: string;
}) => {
  return (
    <>
      <Row>
        <Table className="mt-4" responsive>
          <thead>
            <tr>
              <th colSpan={4} style={{ background: '#f3f3f3', borderTop: '1px solid #dadada' }}>
                <div className="d-flex align-items-center py-1">Banking Account</div>
              </th>
            </tr>
            <tr>
              <th>Bank Name</th>
              <th>Registration Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {banks?.length ? (
              <>
                {banks.map((s: any, i: number) => (
                  <tr key={`bank-${i}`}>
                    <td>
                      <div className="d-flex align-items-center">
                        <i className="fas fa-file mr-2" />
                        <a
                          href={`${s.url}`}
                          target="_blank"
                          style={{
                            textDecoration: 'underline',
                            color: '#5e72e4',
                            backgroundColor: 'transparent',
                          }}
                        >
                          {s.bankName}
                        </a>
                      </div>
                    </td>
                    <td>{moment(s.date).format('MM/DD/YYYY')}</td>
                    <td>
                      <div className="d-flex align-items-center" style={{ gap: 8 }}>
                        <Button
                          style={{ border: 'none', boxShadow: 'none', padding: 0 }}
                          onClick={() => {
                            setCurrentBankingAccount(s?.id || '');
                            setShowBankingModal(true);
                          }}
                        >
                          <i className="fas fa-eye" />
                        </Button>
                        <Button
                          style={{ border: 'none', boxShadow: 'none', padding: 0 }}
                          onClick={(e) => onRemoveBankAccount(e, s.id)}
                        >
                          <i className="far fa-trash-alt text-danger" style={{ cursor: 'pointer' }} />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <></>
            )}
          </tbody>
        </Table>
        {!banks?.length && (
          <Col style={{ paddingLeft: '20px', paddingRight: '20px', marginTop: '20px' }}>
            <Row>
              <Col lg="12">
                <Form onSubmit={onBankSaveFile}>
                  <Row>
                    <Col>
                      <FormGroup style={{ fontSize: '0.8125rem', whiteSpace: 'nowrap', marginTop: '20px' }}>
                        <Label>Bank Name</Label>
                        <Input type="text" value={bankName} onChange={(e) => setBankName(e.target.value)} required />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup style={{ fontSize: '0.8125rem', whiteSpace: 'nowrap', marginTop: '20px' }}>
                        <Label>Routing Number</Label>
                        <Input
                          type="text"
                          value={bankRoutingNumber}
                          onChange={(e) => setRoutingNumber(e.target.value)}
                          minlength="9"
                          maxlength="9"
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup style={{ fontSize: '0.8125rem', whiteSpace: 'nowrap', marginTop: '20px' }}>
                        <Label>Account Number</Label>
                        <Input
                          type="text"
                          value={bankAccountNumber}
                          onChange={(e) => setAccountNumber(e.target.value)}
                          autoComplete="off"
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          minlength="10"
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup style={{ fontSize: '0.8125rem', whiteSpace: 'nowrap', marginTop: '20px' }}>
                        <Label>Confirm Account Number</Label>
                        <Input
                          type="text"
                          value={bankConfirmAccountNumber}
                          onChange={(e) => setConfirmAccountNumber(e.target.value)}
                          autoComplete="off"
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          minlength="10"
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup style={{ fontSize: '0.8125rem', whiteSpace: 'nowrap', marginTop: '20px' }}>
                        <Label for="exampleFile">Upload Blank Check</Label>
                        <Input id="exampleFile" name="file" type="file" onChange={onChangeBankFile} required />
                        <FormText>File extensions: PDF, JPG</FormText>
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <Button
                        color="primary"
                        type="submit"
                        size="sm"
                        style={{ minWidth: '100px' }}
                        // onClick={onBankSaveFile}
                        className="float-right"
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </>
  );
};

export default BankAccountForm;
