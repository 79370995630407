import { useEffect, useContext, useState } from 'react';
import { Row, Col, Button, FormGroup, Label, Input } from 'reactstrap';
import Employee from '../../../services/Employee';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import LoaderSpinner from '../../Core/LoaderSpinner';

const PayRatesForm = ({ id, onSubmit = (_: any) => {} }: { id: string; onSubmit?: (r: any) => void }) => {
  const { token }: any = useContext(AuthContext);
  const [employee, setEmployee] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data = new FormData(e.target as HTMLFormElement);
      const body = {} as any;
      for (const [key, value] of data.entries()) {
        (body as any)[key] = value;
      }
      const response = await Employee(token).updatePayRate(id, body);
      onSubmit(response);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!id) return;
    const load = async () => {
      try {
        setLoading(true);
        const employee = await Employee(token).show(id);
        setEmployee(employee?.data);
      } catch (e) {
        console.error('Failed to load employee');
        setEmployee(null);
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [id, token, setEmployee]);
  return (
    <>
      {loading && (
        <Row>
          <Col style={{ minHeight: '100px' }}>
            <LoaderSpinner />
          </Col>
        </Row>
      )}
      {!loading && (
        <form onSubmit={(e) => handleSubmit(e)}>
          <Row>
            <Col className="mb-4">
              <h3>Update Employee Pay Rate</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="form-control-label">
                <Label>Payment Frequency</Label>
                <Input
                  type="select"
                  className="form-control-alternative"
                  name="paymentFrequency"
                  defaultValue={employee?.paymentFrequency}
                  required
                >
                  <option value=""></option>
                  <option value={'hourly'}>Hourly</option>
                  <option value={'annually'}>Annually</option>
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="form-control-label">
                <Label>Pay Rate</Label>
                <Input
                  type="number"
                  className="form-control-alternative"
                  name="payRate"
                  defaultValue={employee?.payRate}
                  required
                />
              </FormGroup>
            </Col>
            <Col sm={12}>
              <FormGroup className="form-control-label">
                <Label>Reason</Label>
                <Input type="select" name="reason" required>
                  <option value=""></option>
                  {['Annual', 'Promotion', 'Education', 'Other'].map((value) => (
                    <option key={`reason-${value}`} value={value}>
                      {value}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col sm={12}>
              <FormGroup className="form-control-label">
                <Label>Description</Label>
                <Input type="textarea" name="description" />
              </FormGroup>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col className="text-right">
              <Button className="btn-success" type="submit">
                Save
              </Button>
            </Col>
          </Row>
        </form>
      )}
    </>
  );
};

export default PayRatesForm;
