import { Input } from 'reactstrap';
import InputMask from 'react-input-mask';
import { useState } from 'react';
import { toast } from 'react-toastify';
type InputWithHideValueProps = {
  mask?: string | null;
  method: () => Promise<any>;
  disabled?: boolean;
  onChange?: (e: string) => void;
  defaultValue?: string;
  name?: string;
};
const InputWithHideValue = ({
  disabled = false,
  onChange = (_) => {},
  method,
  mask = null,
  defaultValue = mask?.replaceAll('9', 'x'),
  name = '',
}: InputWithHideValueProps) => {
  const [value, setValue] = useState(defaultValue);
  const [mode, setMode] = useState('hide');

  const handleClickShow = () => {
    if (mode === 'show') {
      setMode('hide');
      onChange('');
      setValue(defaultValue);
      return;
    }
    const load = async () => {
      try {
        const data = await method();
        setValue(data.data);
        setMode('show');
      } catch (e: any) {
        const message = String(e?.response?.data?.message || 'Update error!');
        toast.error(message);
      }
    };
    load();
  };

  return (
    <>
      <div className="input-group">
        <Input
          className="form-control-alternative"
          type="text"
          mask={mode === 'hide' ? defaultValue : mask}
          tag={InputMask}
          disabled={mode === 'hide' ? true : disabled}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          name={name}
        />

        <div className="input-group-append">
          <button className="btn btn-primary" type="button" onClick={() => handleClickShow()} disabled={disabled}>
            {mode === 'hide' && <i className="fas fa-eye"></i>}
            {mode === 'show' && <i className="fas fa-eye-slash"></i>}
          </button>
        </div>
      </div>
    </>
  );
};
export default InputWithHideValue;
