/* eslint-disable max-lines */
import { FormGroup, Label, Input, Table } from 'reactstrap';

const PreEmploymentEducationForm = ({ handleChange, formData }: { handleChange: (e: any) => void; formData: any }) => {
  const gradeOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const gradeCollegeOptions = [1, 2, 3, 4];

  return (
    <>
      <FormGroup>
        <Label>8. Education</Label>
        <br />
        <Label>Select the highest grade completed:</Label>
        <div>
          {gradeOptions.map((grade) => (
            <FormGroup check inline key={grade}>
              <Label check>
                <Input
                  type="radio"
                  name="highestGradeCompleted"
                  value={grade}
                  checked={String(formData.highestGradeCompleted) === String(grade)}
                  onChange={handleChange}
                />
                {grade}
              </Label>
            </FormGroup>
          ))}
        </div>
        <br />
        <Label>GED College:</Label>
        <div>
          {gradeCollegeOptions.map((grade) => (
            <FormGroup check inline key={grade}>
              <Label check>
                <Input
                  type="radio"
                  name="gedCollegeCompleted"
                  value={grade}
                  checked={String(formData.gedCollegeCompleted) === String(grade)}
                  onChange={handleChange}
                />
                {grade}
              </Label>
            </FormGroup>
          ))}
        </div>
      </FormGroup>

      <Table bordered responsive>
        <thead>
          <tr>
            <th>Schools</th>
            <th>Name & Location</th>
            <th>Dates Attended</th>
            <th>Course of Study</th>
            <th>Degree/Diploma</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">High School (s)</th>
            <td>
              <Input
                type="text"
                name="highSchoolNameLocation"
                value={formData.highSchoolNameLocation}
                onChange={handleChange}
                maxLength={25}
              />
              <Input
                className="mt-2"
                type="text"
                name="highSchoolNameLocation2"
                value={formData.highSchoolNameLocation2}
                onChange={handleChange}
                maxLength={25}
              />
            </td>
            <td>
              <Input
                type="text"
                name="highSchoolDatesAttended"
                value={formData.highSchoolDatesAttended}
                onChange={handleChange}
              />
              <Input
                className="mt-2"
                type="text"
                name="highSchoolDatesAttended2"
                value={formData.highSchoolDatesAttended2}
                onChange={handleChange}
                maxLength={25}
              />
            </td>
            <td>
              <Input
                type="text"
                name="highSchoolCourseOfStudy"
                value={formData.highSchoolCourseOfStudy}
                onChange={handleChange}
                maxLength={25}
              />
              <Input
                className="mt-2"
                type="text"
                name="highSchoolCourseOfStudy2"
                value={formData.highSchoolCourseOfStudy2}
                onChange={handleChange}
                maxLength={25}
              />
            </td>
            <td>
              <Input
                type="text"
                name="highSchoolDegreeDiploma"
                value={formData.highSchoolDegreeDiploma}
                onChange={handleChange}
                maxLength={25}
              />
              <Input
                className="mt-2"
                type="text"
                name="highSchoolDegreeDiploma2"
                value={formData.highSchoolDegreeDiploma2}
                onChange={handleChange}
                maxLength={25}
              />
            </td>
          </tr>
          <tr>
            <th scope="row">College or University</th>
            <td>
              <Input
                type="text"
                name="collegeUniversityNameLocation"
                value={formData.collegeUniversityNameLocation}
                onChange={handleChange}
                maxLength={25}
              />
              <Input
                className="mt-2"
                type="text"
                name="collegeUniversityNameLocation2"
                value={formData.collegeUniversityNameLocation2}
                onChange={handleChange}
                maxLength={25}
              />
            </td>
            <td>
              <Input
                type="text"
                name="collegeUniversityDatesAttended"
                value={formData.collegeUniversityDatesAttended}
                onChange={handleChange}
                maxLength={25}
              />
              <Input
                className="mt-2"
                type="text"
                name="collegeUniversityDatesAttended2"
                value={formData.collegeUniversityDatesAttended2}
                onChange={handleChange}
                maxLength={25}
              />
            </td>
            <td>
              <Input
                type="text"
                name="collegeUniversityCourseOfStudy"
                value={formData.collegeUniversityCourseOfStudy}
                onChange={handleChange}
                maxLength={25}
              />
              <Input
                className="mt-2"
                type="text"
                name="collegeUniversityCourseOfStudy2"
                value={formData.collegeUniversityCourseOfStudy2}
                onChange={handleChange}
                maxLength={25}
              />
            </td>
            <td>
              <Input
                type="text"
                name="collegeUniversityDegreeDiploma"
                value={formData.collegeUniversityDegreeDiploma}
                onChange={handleChange}
                maxLength={25}
              />
              <Input
                className="mt-2"
                type="text"
                name="collegeUniversityDegreeDiploma2"
                value={formData.collegeUniversityDegreeDiploma2}
                onChange={handleChange}
                maxLength={25}
              />
            </td>
          </tr>
          <tr>
            <th scope="row">Graduate or Professional</th>
            <td>
              <Input
                type="text"
                name="graduateProfessionalNameLocation"
                value={formData.graduateProfessionalNameLocation}
                onChange={handleChange}
                maxLength={25}
              />
              <Input
                className="mt-2"
                type="text"
                name="graduateProfessionalNameLocation2"
                value={formData.graduateProfessionalNameLocation2}
                onChange={handleChange}
                maxLength={25}
              />
            </td>
            <td>
              <Input
                type="text"
                name="graduateProfessionalDatesAttended"
                value={formData.graduateProfessionalDatesAttended}
                onChange={handleChange}
                maxLength={25}
              />
              <Input
                className="mt-2"
                type="text"
                name="graduateProfessionalDatesAttended2"
                value={formData.graduateProfessionalDatesAttended2}
                onChange={handleChange}
                maxLength={25}
              />
            </td>
            <td>
              <Input
                type="text"
                name="graduateProfessionalCourseOfStudy"
                value={formData.graduateProfessionalCourseOfStudy}
                onChange={handleChange}
                maxLength={25}
              />
              <Input
                className="mt-2"
                type="text"
                name="graduateProfessionalCourseOfStudy2"
                value={formData.graduateProfessionalCourseOfStudy2}
                onChange={handleChange}
                maxLength={25}
              />
            </td>
            <td>
              <Input
                type="text"
                name="graduateProfessionalDegreeDiploma"
                value={formData.graduateProfessionalDegreeDiploma}
                onChange={handleChange}
                maxLength={25}
              />
              <Input
                className="mt-2"
                type="text"
                name="graduateProfessionalDegreeDiploma2"
                value={formData.graduateProfessionalDegreeDiploma2}
                onChange={handleChange}
                maxLength={25}
              />
            </td>
          </tr>
          <tr>
            <th scope="row">Any State Credential(s)</th>
            <td>
              <Input
                type="text"
                name="stateCredentialNameLocation"
                value={formData.stateCredentialNameLocation}
                onChange={handleChange}
                maxLength={25}
              />
            </td>
            <td>
              <Input
                type="text"
                name="stateCredentialDatesAttended"
                value={formData.stateCredentialDatesAttended}
                onChange={handleChange}
                maxLength={25}
              />
            </td>
            <td>
              <Input
                type="text"
                name="stateCredentialCourseOfStudy"
                value={formData.stateCredentialCourseOfStudy}
                onChange={handleChange}
                maxLength={25}
              />
            </td>
            <td>
              <Input
                type="text"
                name="stateCredentialDegreeDiploma"
                value={formData.stateCredentialDegreeDiploma}
                onChange={handleChange}
              />
            </td>
          </tr>
        </tbody>
      </Table>

      <FormGroup>
        <Label for="childcareTraining">
          Childcare training completed in the last three years (such as First Aid, CPR, Health and Safety Training,
          ITS-SIDS, CDA etc.):
        </Label>
        <Input
          type="text"
          name="childcareTraining"
          id="childcareTraining"
          value={formData.childcareTraining}
          onChange={handleChange}
          maxLength={130}
        />
      </FormGroup>
    </>
  );
};

export default PreEmploymentEducationForm;
