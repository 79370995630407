/* eslint-disable max-lines */
import { useState, useEffect, useContext } from 'react';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import { Context as AuthContext } from '../../contexts/AuthContext';
import SchoolScheduleEvents from '../../services/SchoolScheduleEvents';

const SchoolEventsBox = ({ schoolId }: { schoolId: string }) => {
  const [calendar, setCalendar] = useState<{ date: string; calendar: any[]; lunch: any[]; snack: any[] }>({
    date: '',
    calendar: [],
    lunch: [],
    snack: [],
  });
  const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'));
  const { token }: any = useContext(AuthContext);

  const getCalendarEvents = async () => {
    if (!schoolId || !currentDate) {
      return;
    }

    try {
      const filters = {
        type: 'calendar',
        schoolId: schoolId,
        startAt: moment(currentDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        endAt: moment(currentDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      };
      const results = { date: currentDate, calendar: [], lunch: [], snack: [] };

      const resultCalendar = await SchoolScheduleEvents(token).getEventsBySchoolIdAndMonth(filters);
      const dataResultCalendar = resultCalendar.data || [];
      results.calendar = dataResultCalendar?.length ? dataResultCalendar : [];

      filters.type = 'lunch';
      const resultLunch = await SchoolScheduleEvents(token).getEventsBySchoolIdAndMonth(filters);
      const dataResultLunch = resultLunch.data || [];
      results.lunch = dataResultLunch?.length ? dataResultLunch : [];

      filters.type = 'snack';
      const resultSnack = await SchoolScheduleEvents(token).getEventsBySchoolIdAndMonth(filters);
      const dataResultSnack = resultSnack.data || [];
      results.snack = dataResultSnack?.length ? dataResultSnack : [];

      setCalendar(results);
    } catch (e: any) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (schoolId === '') {
      return;
    }
    getCalendarEvents();
  }, [currentDate, schoolId]);

  return (
    <>
      <div className="calendar-app-main">
        <div className="text-center">
          <h3 className="text-center text-uppercase">What's Ahead This Week</h3>
          <div className="btn-group mt-2" role="group" aria-label="Basic example">
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => {
                setCurrentDate(
                  moment
                    .utc(currentDate || '')
                    .subtract(1, 'day')
                    .format('YYYY-MM-DD'),
                );
              }}
            >
              <i className="fc-icon fc-icon-chevron-left" style={{ fontSize: '1.5em' }}></i>
            </button>
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => {
                setCurrentDate(
                  moment
                    .utc(currentDate || '')
                    .add(1, 'day')
                    .format('YYYY-MM-DD'),
                );
              }}
            >
              <i className="fc-icon fc-icon-chevron-right" style={{ fontSize: '1.5em' }}></i>
            </button>
          </div>

          <h3 className="text-muted mt-3">{moment(currentDate || '').format('MM/DD/YYYY')}</h3>
        </div>
        {/* {isMobile ? ( */}
        <div className="parent-events-box">
          <Row style={{ border: '1px solid #a9bfd630', textAlign: 'center' }} className="mt-2">
            {calendar?.calendar?.length > 0 ? (
              <>
                <Col sm="12" style={{ padding: 6, background: '#5e72e4', color: '#FFF' }}>
                  <h4 style={{ color: '#FFF', margin: 0 }}>EVENTS</h4>
                </Col>
                {calendar?.calendar.map((c: any, i: number) => {
                  const objectTitle = c?.title ? JSON.parse(c.title) : {};
                  const name = objectTitle?.name || 'Event';
                  return (
                    <Col
                      key={`calendar-${i}`}
                      sm="12"
                      style={{ border: '1px solid #a9bfd630', lineHeight: 1.2, padding: 4 }}
                    >
                      <span>
                        <strong style={{ fontSize: 15 }}>{name}</strong>
                        <br />
                        <small>
                          {moment.utc(c?.start).format('hh:mma')} - {moment.utc(c?.end).format('hh:mma')}
                        </small>
                      </span>
                    </Col>
                  );
                })}
              </>
            ) : (
              <>
                <Col sm="12" style={{ padding: 6, background: '#5e72e4', color: '#FFF' }}>
                  <h4 style={{ color: '#FFF', margin: 0 }}>EVENTS</h4>
                </Col>
                <Col sm="12" style={{ border: '1px solid #a9bfd630', padding: 4, lineHeight: 1.2 }}>
                  <span>
                    <strong style={{ fontSize: 15 }}>-</strong>
                    <br />
                  </span>
                </Col>
              </>
            )}

            {calendar?.lunch?.length > 0 ? (
              <>
                <Col sm="12" style={{ padding: 6, background: '#5e72e4', color: '#FFF' }}>
                  <h4 style={{ color: '#FFF', margin: 0 }}>LUNCH</h4>
                </Col>
                {calendar?.lunch.map((c: any, i: number) => {
                  const objectTitle = c?.title ? JSON.parse(c.title) : {};

                  const lunch = [
                    { type: 'Protein', value: objectTitle?.protein },
                    { type: 'Vegetable', value: objectTitle?.vegetable },
                    { type: 'Fruits', value: objectTitle?.fruits },
                    { type: 'Drink', value: objectTitle?.drink },
                  ];

                  return (
                    <>
                      {lunch.map((l: any, il: number) => {
                        return (
                          <Col
                            key={`lunch-${i}-${il}`}
                            sm="12"
                            style={{ border: '1px solid #a9bfd630', padding: 4, lineHeight: 1.2 }}
                          >
                            <span>
                              <strong style={{ fontSize: 15 }}>{l.type}</strong>
                              <br />
                              <small>{l?.value || '-'}</small>
                            </span>
                          </Col>
                        );
                      })}
                    </>
                  );
                })}
              </>
            ) : (
              <>
                <Col sm="12" style={{ padding: 6, background: '#5e72e4', color: '#FFF' }}>
                  <h4 style={{ color: '#FFF', margin: 0 }}>LUNCH</h4>
                </Col>
                <Col sm="12" style={{ border: '1px solid #a9bfd630', padding: 4, lineHeight: 1.2 }}>
                  <span>
                    <strong style={{ fontSize: 15 }}>-</strong>
                    <br />
                  </span>
                </Col>
              </>
            )}

            {calendar?.snack?.length > 0 ? (
              <>
                <Col sm="12" style={{ padding: 6, background: '#5e72e4', color: '#FFF' }}>
                  <h4 style={{ color: '#FFF', margin: 0 }}>SNACKS</h4>
                </Col>
                {calendar?.snack.map((c: any, i: number) => {
                  const objectTitle = c?.title ? JSON.parse(c.title) : {};
                  const snack = [
                    { type: 'AM', value: objectTitle?.am },
                    { type: 'PM', value: objectTitle?.pm },
                  ];
                  return (
                    <>
                      {snack.map((l: any, il: number) => {
                        return (
                          <Col
                            key={`snack-${i}-${il}`}
                            sm="12"
                            style={{ border: '1px solid #a9bfd630', padding: 4, lineHeight: 1.2 }}
                          >
                            <span>
                              <strong style={{ fontSize: 15 }}>{l.type}</strong>
                              <br />
                              <small>{l?.value || '-'}</small>
                            </span>
                          </Col>
                        );
                      })}
                    </>
                  );
                })}
              </>
            ) : (
              <>
                <Col sm="12" style={{ padding: 6, background: '#5e72e4', color: '#FFF' }}>
                  <h4 style={{ color: '#FFF', margin: 0 }}>SNACKS</h4>
                </Col>
                <Col sm="12" style={{ border: '1px solid #a9bfd630', padding: 4, lineHeight: 1.2 }}>
                  <span>
                    <strong style={{ fontSize: 15 }}>-</strong>
                    <br />
                  </span>
                </Col>
              </>
            )}
          </Row>
        </div>
      </div>
    </>
  );
};
export default SchoolEventsBox;
