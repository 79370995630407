/* eslint-disable max-lines */
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert,
  Media,
} from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { Link, Redirect, useParams } from 'react-router-dom';
import UserService from '../../../services/User';
import CommonHeader from '../../../components/Headers/CommonHeader';
import ProfileTab from './TabContent/ProfileTab';
import TimeCardTab from './TabContent/TimeCardTab';
import EmployeeDetailsTab from './TabContent/EmployeeDetailsTab';
import ChildrenTab from './TabContent/ChildrenTab';
import MedicalTab from './TabContent/MedicalTab';
import AsideProfile from './ProfileRightBar';
import isParent from '../../../helpers/isParent';
import NotificationTab from './TabContent/NotificationTab';
import FilesTab from './TabContent/FilesTab';
import Employee from '../../../services/Employee';
import { toast } from 'react-toastify';
import moment from 'moment';
import isClassroom from '../../../helpers/isClassroom';
import ClassPermissionsTab from './TabContent/ClassPermissionsTab';
import OpacityLoaderSpinner from '../../../components/Core/OpacityLoaderSpinner';
import MessagingListTalk from '../Messaging/MessagingListTalk';
import { isMobile } from 'react-device-detect';
import isAdmin from '../../../helpers/isAdmin';
import { ucfirst } from '../../../helpers/string';
import { PayRatesList } from '../../../components/Employee';

const Profile = () => {
  const { id: userId } = useParams<{ id: string }>();
  const { token, user: loggedUser, currentSchool }: any = useContext(AuthContext);
  const [kidsList, setKidsList] = useState([]);
  const [reload, setReload] = useState(false);
  const [currentUser, setCurrentUser] = useState({} as any);
  const [terminationDateDay, setTerminationDateDay] = useState('');
  const [terminationDateMonth, setTerminationDateMonth] = useState('');
  const [terminationDateYear, setTerminationDateYear] = useState('');
  const [isTerminationFuture, setIsTerminationFuture] = useState(false);
  const [isParentUser, setIsParentUser] = useState(false);
  const [isUserHimself, setIsUserHimself] = useState(false);
  const [isClassroomUser, setIsClassroomUser] = useState<boolean | null>(null);
  const [loadingUser, setLoadingUser] = useState(true);

  const [currSchoolWhenStart, _] = useState(currentSchool);
  const [redirctToList, setRedirctToList] = useState(false);
  useEffect(() => {
    if (currentSchool && currentSchool !== currSchoolWhenStart) setRedirctToList(true);
  }, [currentSchool, currSchoolWhenStart]);

  useEffect(() => {
    getKidsByUserId();
    getUserById();
  }, []);

  useEffect(() => {
    getKidsByUserId();
    getUserById();
    setIsUserHimself(userId === loggedUser.id);
  }, [userId]);

  useEffect(() => {
    setLoadingUser(true);
    if (Object.keys(currentUser).length === 0 && currentUser.constructor === Object) {
      return;
    }
    setIsParentUser(isParent(currentUser));
    setIsClassroomUser(isClassroom(currentUser));
    setLoadingUser(false);
  }, [currentUser]);

  useEffect(() => {
    if (isClassroomUser === null) {
      return;
    }
    let tab = 'profile';
    if (isClassroomUser) {
      tab = 'classes';
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    setActiveTab(urlParams.get('tab') || tab);
  }, [isClassroomUser]);

  const getKidsByUserId = async () => {
    try {
      const result = await UserService(token).listKids(userId);
      setKidsList(result?.data || []);
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'Invalid user ID');
    }
  };

  const getUserById = async () => {
    try {
      const result = await UserService(token).show(userId);
      setCurrentUser(result?.data || {});
      if (result?.data?.employee?.terminationDate) {
        const terminationDate = moment(result?.data?.employee?.terminationDate);
        setTerminationDateDay(terminationDate?.format('Do'));
        setTerminationDateMonth(terminationDate?.format('MMMM'));
        setTerminationDateYear(terminationDate?.format('YYYY'));
        setIsTerminationFuture(terminationDate >= moment(moment().format('YYYY-MM-DD')));
      }
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'Invalid user ID');
    }
  };

  const customStyles = {
    navLink: {
      minHeight: '42px',
      paddingTop: '8px',
    },
  };
  const [activeTab, setActiveTab] = useState('');

  const visibleChildrenTab = kidsList?.length;

  const handleActivatedEmployee = async (employeeId: string) => {
    try {
      await Employee(token).activated(employeeId);
      getUserById();
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Is not possible change status!');
    }
  };

  const userTabs = {
    profile: {
      content: <ProfileTab refresh={getUserById} />,
      name: 'Profile',
      icon: 'fas fa fa-user',
      visible: !isClassroomUser,
    },
    time: {
      content: <TimeCardTab />,
      name: 'Time Card',
      icon: 'fas fa fa-clock',
      visible: !isParentUser && currentUser?.employee?.id,
    },
    employee: {
      content: <EmployeeDetailsTab />,
      name: `Employee${!isMobile ? ' Details' : ''}`,
      icon: 'fas fa fa-briefcase',
      visible: !isParentUser && !isClassroomUser,
    },
    children: {
      content: <ChildrenTab />,
      name: `Children`,
      icon: 'fas fa fa-child',
      visible: !!visibleChildrenTab,
    },
    medical: {
      content: <MedicalTab />,
      name: `Medical`,
      icon: 'fas fa-notes-medical',
      visible: !isParentUser && !isClassroomUser,
    },
    notifications: {
      content: <NotificationTab />,
      name: `Notifications`,
      icon: 'fas fa-bell',
      visible: false,
    },
    files: {
      content: <FilesTab reload={reload} />,
      name: `Files`,
      icon: 'fas fa-file',
      visible: !isParentUser && !isClassroomUser,
    },
    classes: {
      content: <ClassPermissionsTab userId={currentUser?.id} />,
      name: `Classes`,
      icon: 'fas fa-lock',
      visible: isClassroomUser,
    },
    messages: {
      content: <MessagingListTalk userId={userId} />,
      name: `Messages`,
      icon: 'fas fa-comment',
      visible: isAdmin(loggedUser),
    },
    payrates: {
      content: <PayRatesList employeeId={currentUser?.employee?.id} />,
      name: `Pay Rates`,
      icon: 'fas fa-money-bill-wave',
      visible: isAdmin(loggedUser),
    },
  };

  const getTabName = (tabKey: string) => {
    return (userTabs as any)[tabKey]?.name || ucfirst(tabKey);
  };

  const isVisible = (tabKey: string) => {
    return (userTabs as any)[tabKey]?.visible;
  };

  return (
    <>
      {redirctToList ? <Redirect to={'/directory'} /> : <></>}
      <CommonHeader />
      {loadingUser ? <OpacityLoaderSpinner /> : <></>}
      <Container className="mt--8" fluid style={loadingUser ? { display: 'none' } : { display: 'block' }}>
        <Row>
          <Col className="order-xl-2" xl="12" style={isMobile ? { paddingLeft: 5, paddingRight: 5 } : {}}>
            <Card className="bg-secondary shadow">
              {currentUser?.status === 'TERMINATED' ? (
                <Alert color="warning" className="mt-1 ml-1 mr-1">
                  <b>Terminated:</b> {currentUser?.firstName}'s last day of work {isTerminationFuture ? 'is' : 'was'}{' '}
                  {terminationDateMonth || ''} {terminationDateDay || ''}, {terminationDateYear || ''}.{' '}
                  <Link
                    to={`/directory?id=${currentUser?.employee?.id}&type=revoke`}
                    className="text-white"
                    style={{ textDecoration: 'underline white' }}
                  >
                    <b>Revoke Termination</b>
                  </Link>
                  {' | '}
                  <Link
                    to={`/directory?id=${currentUser?.employee?.id}&type=rehire`}
                    className="text-white"
                    style={{ textDecoration: 'underline white' }}
                  >
                    <b>Rehire</b>
                  </Link>
                </Alert>
              ) : (
                <></>
              )}
              <CardHeader className="bg-white border-0 mb-0 pb-0" style={isMobile ? { padding: '1rem 0.5rem' } : {}}>
                <div style={{ marginBottom: '12px' }}>
                  <Media className="align-items-center">
                    <span className="avatar rounded-circle" style={{ width: '60px', height: '60px' }}>
                      <img
                        alt={`${currentUser?.firstName} ${currentUser?.lastName}`}
                        src={
                          currentUser?.picture
                            ? `${currentUser?.picture}`
                            : require('../../../assets/img/user-default.png').default
                        }
                        style={{ height: '100%' }}
                      />
                    </span>
                    <Media className="ml-2">
                      <span className="mb-0 text-sm font-weight-bold">{`${currentUser?.firstName} ${currentUser?.lastName}`}</span>
                    </Media>
                  </Media>
                </div>
                <div className={isMobile ? 'd-flex justify-content-center' : ''}>
                  <Nav className="border-0 nav-profile" tabs>
                    {Object.keys(userTabs).map((tab: string, i: number) => (
                      <>
                        {isVisible(tab) && (
                          <NavItem className="cursor-pointer" key={`nav-item_${i}`}>
                            <NavLink
                              className="border-0 cursor-pointer font-weight-bold nav-link-profile"
                              style={customStyles.navLink}
                              active={activeTab === tab}
                              onClick={() => setActiveTab(tab)}
                            >
                              <i className={`${(userTabs as any)[tab]?.icon} ${!isMobile ? 'mr-2' : ''}`} />
                              <span>{getTabName(tab)}</span>
                            </NavLink>
                          </NavItem>
                        )}
                      </>
                    ))}
                  </Nav>
                </div>
              </CardHeader>
              <CardBody className="d-flex flex-wrap w-100">
                <Col xl="8" lg="8" md="12">
                  <TabContent activeTab={activeTab}>
                    {Object.keys(userTabs).map((tab: string, i: number) => (
                      <TabPane key={`tab-pane_${i}`} tabId={tab}>
                        {isVisible(tab) && activeTab === tab && (userTabs as any)[tab].content}
                      </TabPane>
                    ))}
                  </TabContent>
                </Col>
                <Col xl="4" lg="4" md="12">
                  <AsideProfile
                    reload={reload}
                    handleReload={setReload}
                    currentUser={currentUser}
                    isUserHimself={isUserHimself}
                    handleActivatedEmployee={handleActivatedEmployee}
                  />
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
