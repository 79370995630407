import { Col, Modal, Row } from 'reactstrap';
import { useEffect, useState } from 'react';
import { fillForm } from '../../../../services/PreEmploymentPDFService';

type PreEmploymentFormModalProps = {
  isOpen: boolean;
  data: any;
  toggleModal: (e?: any) => void;
};

const PreEmploymentFormModal = ({ isOpen, data, toggleModal }: PreEmploymentFormModalProps) => {
  const [pdf, setPdf] = useState('');

  useEffect(() => {
    if (isOpen) {
      loadPdf();
    }
  }, [isOpen]);

  const loadPdf = async () => {
    try {
      const pdfBytes = await fillForm(data.fileUrl, data.formData, data.employmentHistory);
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      const docUrl = URL.createObjectURL(blob);
      setPdf(docUrl);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Modal className="modal-dialog-centered" size="xl" isOpen={isOpen} toggle={(e: any) => toggleModal(e)}>
        <div className="modal-header pb-1">
          <h5 className="modal-title">{data?.options?.title || ''}</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleModal(e)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <Row className="mt-2">
            <Col lg="12" className="mt-4" style={{ maxHeight: '500px', overflow: 'scroll' }}>
              <iframe src={pdf} style={{ minHeight: 500, width: '100%' }} />
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default PreEmploymentFormModal;
