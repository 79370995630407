/* eslint-disable max-lines */
import { Modal, Button } from 'reactstrap';
import { Card, CardBody, Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { useState, useEffect, useContext } from 'react';
import { Context as AuthContext } from '../../contexts/AuthContext';
import OpacityLoaderSpinner from '../Core/OpacityLoaderSpinner';
import KidsViewCamera from '../Kids/KidsViewCamera';
import UserService from '../../services/User';
import { ErrorsList } from '../../views/pages/Home/ErrorsList';
import BaseHomeMobile from '../../views/pages/BaseHomeMobile';
import TimelineTab from '../../views/pages/Kids/TabContent/TimelineTab';
import SchoolEventsBox from '../Calendar/SchoolEventsBox';

const customStyles = {
  navLink: {
    minHeight: '42px',
    paddingTop: '8px',
    paddingLeft: isMobile ? 8 : undefined,
    paddingRight: isMobile ? 8 : undefined,
  },
};

const ParentDashboard = ({ currentUser }: { currentUser: any }) => {
  const isTablet = window.innerWidth <= 1112 && window.innerWidth > 768 && isMobile;
  const [loading, setLoading] = useState(false);

  const [schoolId, setSchoolId] = useState('');
  const { currentSchool, token }: any = useContext(AuthContext);
  const [kidsUser, setKidsUser] = useState<any>([]);
  const [selectedKid, setSelectedKid] = useState('');
  const [modalKidsViewCamera, setModalKidsViewCamera] = useState(false);
  const [breadcrumbModal, setBreadcrumbModal] = useState('');
  const [modalKidsViewCameraSelectedKid, setModalKidsViewCameraSelectedKid] = useState('');

  const [activeTab, setActiveTab] = useState('');

  const getAuthUserData = async () => {
    setLoading(true);
    try {
      const result = await UserService(token).listKids(currentUser.id);
      if (result?.data) {
        setKidsUser(
          result?.data.map((kidsUser: any) => {
            return {
              id: kidsUser.kids.id,
              firstName: kidsUser.kids.firstName,
              lastName: kidsUser.kids.lastName,
              fullName: `${kidsUser.kids.firstName} ${kidsUser.kids.lastName}`,
              picture: kidsUser.kids.picture,
              isCheckedIn: kidsUser.kids.isCheckedIn,
              class: kidsUser.kids.enrollment.class,
              attendances: kidsUser.kids.attendances.reverse(),
            };
          }),
        );
        setSelectedKid(result.data[0].kids.id);
      }
      setLoading(false);
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'Invalid user ID');
      setLoading(false);
    }
  };

  const toggleModalKidsViewCamera = () => {
    setModalKidsViewCameraSelectedKid('');
    getAuthUserData();
    setModalKidsViewCamera(!modalKidsViewCamera);
  };

  useEffect(() => {
    setSchoolId(currentSchool.value || '');
    getAuthUserData();
  }, []);

  useEffect(() => {
    setSchoolId(currentSchool.value || '');
  }, [currentSchool]);

  return (
    <>
      <Container className="mt--7 " fluid>
        {isMobile && !isTablet && <BaseHomeMobile />}
        <Row className={!isMobile ? 'mt-5' : ''}>
          <Col xl="12">
            <Card className="shadow">
              <CardBody style={isMobile ? { paddingTop: 0 } : {}}>
                <Row className="mb-3">
                  <Col md="12">
                    <ErrorsList />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <SchoolEventsBox schoolId={schoolId} />
                  </Col>
                </Row>
              </CardBody>
              <Card className="shadow">
                <CardBody style={isMobile ? { paddingTop: 0 } : {}}>
                  <Nav className="border-0 nav-profile pt-4" tabs>
                    {kidsUser.map((tab: any, i: number) => {
                      return (
                        <NavItem className="cursor-pointer" key={`nav-item_${i}`}>
                          <NavLink
                            className="border-0 cursor-pointer font-weight-bold nav-link-profile"
                            style={customStyles.navLink}
                            active={(!activeTab && i === 0) || activeTab === tab.id}
                            onClick={() => {
                              setSelectedKid(tab.id);
                              setActiveTab(tab.id);
                            }}
                          >
                            <div className="align-items-center media">
                              <span className="avatar avatar-sm rounded-circle">
                                <img alt={tab.fullName} src={tab.picture} style={{ height: '100%' }} />
                              </span>
                              <div className="ml-2 d-lg-block media">
                                <span className="mb-0 text-sm font-weight-bold">{tab.fullName}</span>
                              </div>
                            </div>
                          </NavLink>
                        </NavItem>
                      );
                    })}
                  </Nav>
                  {selectedKid ? (
                    <Row className="mt-3 p-2">
                      <Col xs="12" style={isMobile ? { paddingLeft: 5, paddingRight: 5 } : {}}>
                        <Button
                          className="mb-3"
                          color="primary"
                          size="sm"
                          style={{ width: '100%', height: 38 }}
                          onClick={() => toggleModalKidsViewCamera()}
                        >
                          <i className="fas fa-camera"></i>
                          &nbsp;Cameras
                        </Button>
                      </Col>
                      <Col xs="12">
                        <TimelineTab kidsIdProp={selectedKid} />
                      </Col>
                    </Row>
                  ) : (
                    ''
                  )}
                </CardBody>
              </Card>
            </Card>
          </Col>
        </Row>
      </Container>
      {loading ? <OpacityLoaderSpinner /> : <></>}
      <Modal
        className="modal-dialog-centered"
        isOpen={modalKidsViewCamera}
        toggle={() => toggleModalKidsViewCamera()}
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title w-100" id="Label">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb parentsDash">
                <li className="breadcrumb-item" style={{ paddingTop: '8px' }}>
                  <a
                    href="#"
                    style={{ color: '#5e72e4' }}
                    onClick={(e) => {
                      e.preventDefault();
                      setModalKidsViewCameraSelectedKid('');
                    }}
                  >
                    Your Kids
                  </a>
                </li>
                {breadcrumbModal !== '' && (
                  <li className="breadcrumb-item active" aria-current="page" style={{ color: '#8898aa' }}>
                    <div className="align-items-center media">
                      <span className="avatar avatar-sm rounded-circle">
                        <img
                          alt={kidsUser.find((item: any) => item.id === selectedKid).fullName}
                          src={kidsUser.find((item: any) => item.id === selectedKid).picture}
                          style={{ height: '100%' }}
                        />
                      </span>
                      <div className="ml-2 d-none d-lg-block media">
                        <span className="mb-0 font-weight-bold">
                          {kidsUser.find((item: any) => item.id === selectedKid).fullName}
                        </span>
                      </div>
                    </div>
                  </li>
                )}
              </ol>
            </nav>
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModalKidsViewCamera()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0" style={{ minHeight: '300px' }}>
          <KidsViewCamera
            kidsList={kidsUser}
            currentUser={currentUser}
            setSelectedKidObject={(kid: any) => {
              setBreadcrumbModal(kid.fullName || '');
            }}
            selectedKid={modalKidsViewCameraSelectedKid}
            setSelectedKid={(e: any) => setModalKidsViewCameraSelectedKid(e)}
          />
        </div>
      </Modal>
    </>
  );
};

export default ParentDashboard;
