/* eslint-disable max-lines */
import { Button, Col, FormGroup, Input, Label, Modal, Row, FormText } from 'reactstrap';
import BankingAccounts from '../../../services/BankingAccounts';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import InputWithHideValue from '../../../components/Core/InputWithHideValue';

type BankingAccountModalProps = {
  showBankingModal: boolean;
  bankAccountId: string;
  banks: any[];
  setShowBankingModal: (e?: any) => void;
  editMode?: boolean;
  onSave?: (e?: any) => void;
  employeeId?: string;
  disabled?: boolean;
};

const BankingAccountModal = ({
  showBankingModal,
  bankAccountId,
  banks,
  setShowBankingModal,
  editMode = false,
  onSave = () => {},
  employeeId,
  disabled = false,
}: BankingAccountModalProps) => {
  const { token }: any = useContext(AuthContext);
  const bank = banks?.find((b) => b?.id === bankAccountId);
  const isDisabled = !editMode;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const data = new FormData(e.target as HTMLFormElement);
      const body = {} as any;
      for (const [key, value] of data.entries()) {
        (body as any)[key] = value;
      }
      body.employeeId = employeeId || bank.employeeId;
      if (body.accountNumber !== body.confirmAccountNumber) {
        toast.error('account number must be equal to confirm account number');
        return;
      }
      if (bankAccountId) await BankingAccounts(token).delete(bankAccountId);
      const file = body.file;
      delete body.file;
      const banking = await BankingAccounts(token).create(body);

      const formData = new FormData();
      formData.append('document', file as any);
      await BankingAccounts(token).uploadFile(banking?.data?.id, formData);
      onSave(banking);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Update error!');
      toast.error(message);
    }
  };
  return (
    <Modal
      className="modal-dialog-centered"
      size="xl"
      isOpen={showBankingModal}
      toggle={() => setShowBankingModal(!showBankingModal)}
    >
      <div className="modal-header pb-1">
        <h5 className="modal-title">Banking Account</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => setShowBankingModal(!showBankingModal)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="modal-body pt-0" style={!isDisabled ? { paddingBottom: '0' } : {}}>
          <Col lg="12">
            <Row>
              <Col lg={!isDisabled ? '6' : ''}>
                <FormGroup style={{ fontSize: '0.8125rem', whiteSpace: 'nowrap', marginTop: isDisabled ? '20px' : '' }}>
                  <Label>Bank Name</Label>
                  <Input type="text" name="bankName" defaultValue={bank?.bankName || ''} disabled={isDisabled} />
                </FormGroup>
              </Col>
              <Col lg={!isDisabled ? '6' : ''}>
                <FormGroup style={{ fontSize: '0.8125rem', whiteSpace: 'nowrap', marginTop: isDisabled ? '20px' : '' }}>
                  <Label>Routing Number</Label>
                  {bank && !disabled ? (
                    <InputWithHideValue
                      defaultValue={bank?.routingNumber || ''}
                      method={async () => await BankingAccounts(token).showSecretNumber(bank.id, 'routing_number')}
                      name="routingNumber"
                      disabled={disabled}
                    />
                  ) : (
                    <Input
                      type="text"
                      name="routingNumber"
                      defaultValue={bank?.routingNumber || ''}
                      disabled={isDisabled}
                    />
                  )}
                </FormGroup>
              </Col>
              <Col lg={!isDisabled ? '6' : ''}>
                <FormGroup style={{ fontSize: '0.8125rem', whiteSpace: 'nowrap', marginTop: isDisabled ? '20px' : '' }}>
                  <Label>Account Number</Label>
                  {bank && !disabled ? (
                    <InputWithHideValue
                      defaultValue={bank?.accountNumber || ''}
                      method={async () => await BankingAccounts(token).showSecretNumber(bank.id, 'account_number')}
                      name="accountNumber"
                      disabled={disabled}
                    />
                  ) : (
                    <Input
                      type="text"
                      name="accountNumber"
                      defaultValue={bank?.accountNumber || ''}
                      disabled={isDisabled}
                      autoComplete="off"
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  )}
                </FormGroup>
              </Col>
              {!isDisabled && (
                <>
                  <Col lg="6">
                    <FormGroup
                      style={{ fontSize: '0.8125rem', whiteSpace: 'nowrap', marginTop: isDisabled ? '20px' : '' }}
                    >
                      <Label>Confirm Account Number</Label>
                      <Input
                        type="text"
                        name="confirmAccountNumber"
                        defaultValue=""
                        disabled={isDisabled}
                        autoComplete="off"
                        onPaste={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup
                      style={{ fontSize: '0.8125rem', whiteSpace: 'nowrap', marginTop: isDisabled ? '20px' : '' }}
                    >
                      <Label for="exampleFile">Upload Blank Check</Label>
                      <Input id="exampleFile" name="file" type="file" />
                      <FormText>File extensions: PDF, JPG</FormText>
                    </FormGroup>
                  </Col>
                </>
              )}
              {isDisabled && !disabled && (
                <Col>
                  <FormGroup style={{ marginTop: '44px' }}>
                    <a href={bank?.url || '#'} target="_blank">
                      <Button type="button">
                        <i className="fas fa-download" style={{ fontSize: 19 }} /> Blank Check
                      </Button>
                    </a>
                  </FormGroup>
                </Col>
              )}
            </Row>
          </Col>
        </div>
        {!isDisabled && (
          <div className="modal-footer pt-0">
            <Button className="btn-success">Save</Button>
          </div>
        )}
      </form>
    </Modal>
  );
};

export default BankingAccountModal;
