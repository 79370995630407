import { useEffect, useContext, useState } from 'react';
import { ModalProps, Modal } from 'reactstrap';
import User from '../../services/User';
import { Context as AuthContext } from '../../contexts/AuthContext';

interface ModalProfileProps extends ModalProps {
  user: any;
}
const ModalProfile = ({ className, isOpen, toggle, size, user, children }: ModalProfileProps) => {
  const { token }: any = useContext(AuthContext);
  const [loadedUser, setLoadedUser] = useState<any>(null);
  useEffect(() => {
    if (user?.firstName && user?.lastName && user?.picture) return;
    const load = async () => {
      try {
        const response = await User(token).show(user.id);
        user = response.data;
        setLoadedUser(response.data);
      } catch (e) {
        console.error('Failed to load user profile', e);
      }
    };
    load();
  }, [user]);
  return (
    <Modal className={className} isOpen={isOpen} toggle={toggle} size={size}>
      <div className="modal-header">
        <div className="d-flex align-items-center">
          {((user && Object.values(user).length !== 0) || loadedUser) && (
            <>
              <div style={{ width: '35px', height: '35px', borderRadius: '50%', overflow: 'hidden' }}>
                <img
                  src={user?.picture || loadedUser?.picture || ''}
                  alt="User Profile"
                  className="img-fluid"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </div>
              <span className="ml-2 text-primary font-weight-bold text-sm">{`${
                user?.firstName || loadedUser?.firstName || '-'
              } ${user?.lastName || loadedUser?.lastName || '-'}`}</span>
            </>
          )}
        </div>
        <div className="d-flex p-1">
          <a onClick={(e) => (toggle ? toggle(e as any) : '')} className="cursor-pointer" style={{ color: '#525f7f' }}>
            <i className="fas fa-times" />
          </a>
        </div>
      </div>
      <div className="modal-body mt-0 pt-0">{children}</div>
    </Modal>
  );
};

export default ModalProfile;
